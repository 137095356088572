import 'AngularHttpQueueAndRetry/angularModule';
import 'OfflineMode/angularModule';
import { statsigClientProvider } from 'StatsigClientProvider';
import { asStatsig } from 'Users';
import { debounce } from 'lodash/fp';

export default angular
    .module('FrontRoyal.Status', ['HttpQueueAndRetry', 'OfflineMode'])
    .factory('FrontRoyal.StatusPoller', [
        '$injector',
        $injector => {
            const $http = $injector.get('$http');
            const HttpQueue = $injector.get('HttpQueue');
            const $timeout = $injector.get('$timeout');
            const $document = $injector.get('$document');
            const offlineModeManager = $injector.get('offlineModeManager');
            const $rootScope = $injector.get('$rootScope');
            const oneHour = 1000 * 60 * 60;
            let pollingPromise;
            let failureCount;

            return {
                activate() {
                    failureCount = 0;

                    // global hotkey
                    $($document).on('keydown.StatusPoller', $event => {
                        if ($event.ctrlKey && $event.key === 'g') {
                            this.send();
                        }
                    });

                    // Sometimes we get two focus events when the browser gets focused, so debounce
                    const focusHandling = debounce(1000, () => {
                        this.send();
                    });
                    $(window).on('focus', focusHandling);

                    // rolling status polling
                    this.reset();
                },

                deactivate() {
                    this._cancel();
                    $($document).off('.StatusPoller');
                },

                send(synchronousCallback = undefined) {
                    if (offlineModeManager.inOfflineMode) {
                        this.reset();
                        return Promise.resolve();
                    }

                    this._cancel();

                    // flush any local changes up to the server before the ping to prevent us from overriding
                    // local changes with data from the server
                    return offlineModeManager.frontRoyalStore.flush().then(() => {
                        $http
                            .get(`${window.ENDPOINT_ROOT}/api/status/simple.json`, {
                                'FrontRoyal.ApiErrorHandler': { skip: true },
                            })
                            .then(() => {
                                failureCount = 0;
                            })
                            .catch(response => {
                                failureCount += 1;

                                // See https://sentry.io/pedago/front-royal/issues/312756058/?query=config
                                // We know that the changes made here will cause a new Sentry error due to changes in the
                                // fingerprint.
                                if (!response.config) {
                                    const error = new Error('response.config undefined');
                                    error.extra = {
                                        responseKeys: _.keys(response),
                                        responseType: typeof response,
                                    };
                                    if (typeof response === 'string') {
                                        error.extra.responseContent = response.slice(0, 1000);
                                    }

                                    if (response && response.status) {
                                        error.extra.status = response.status;
                                    }

                                    $injector.get('DialogModal').showFatalError();
                                    $injector.get('ErrorLogService').notifyInProd(error);
                                    return;
                                }

                                // We don't care if this fails, we'll try again later. So catch the errors
                                HttpQueue.unfreezeAfterError(response.config);
                            })
                            .finally(() => {
                                if (synchronousCallback) synchronousCallback();
                                this.reset();

                                // trigger a Statsig refresh
                                statsigClientProvider.waitForClient({ caller: 'Status#send' }).then(client => {
                                    client.updateUserSync(asStatsig($rootScope.currentUser));
                                });
                            });
                    });
                },

                reset() {
                    this._cancel();

                    let delay = this._getDelay();

                    // never wait longer than an hour, so that if we go offline for a while,
                    // after coming back the pings eventually start up again.
                    delay = Math.min(delay, oneHour);

                    pollingPromise = $timeout(() => {
                        this.send();
                    }, delay);
                },

                _cancel() {
                    if (pollingPromise) {
                        $timeout.cancel(pollingPromise);
                        pollingPromise = null;
                        return true;
                    }
                    return false;
                },

                _getDelay() {
                    // We want to re-calculate the baseDelay every time this
                    // module is reset, which is when it's initialized, or when
                    // we have a successful http call. We do this in case the
                    // ENV variable sent down in the config changes.
                    const baseDelay =
                        $injector.get('ConfigFactory').getSync(true)?.base_status_poll_delay_ms || 60 * 1000;

                    // exponential backoff in case of failure (avoid retry storm)
                    return failureCount ? baseDelay * failureCount ** 2 : baseDelay;
                },
            };
        },
    ]);
