/*
    # Overview

    StudentDashboardContentProvider is a class that handles fetching and caching content that is needed for the
    student dashboard.

    It has one public method, getStudentDashboardContent, which takes a list of params describing the current state of
    the user. When called repeatedly with the same params, it returns a cached version of the result.

    # FrontRoyalStore caching

    StudentDashboardContentProvider will initially look for a record in frontRoyalStore's studentDashboardContentFetches table.
    If a record is found there, it means that the student dashboard content has already been fetched for the user's current state,
    and all of the necessary streams and playlists should already be stored in frontRoyalStore.

    The studentDashboardContentFetches table needs to be keyed off of any params that
    might indicate a change in the content that is needed on the student dashboard. The params that are currently used are userId, cohortVersionId,
    prefLocale, and contentViewsRefreshUpdatedAt. If any of those change, then we will not use an existing record from that table.

    Using contentViewsRefreshUpdatedAt here isn't ideal. That means that any time anything is published, users out in the wild will be forced
    to make a new student dashboard request. In the vast majority of cases, they won't get anything new. Eventually, we should come up with a
    tighter way to determine when users need to pull content changes. See https://trello.com/c/b6emlEqv

    Two other params are passed to getStudentDashboardContent and handled a bit differently:

    * favoriteLessonStreamLocalePackIds. If this changes, it indicates that we need to reload the student dashboard content. But, this can change
        while we are in offline mode, in which case it would be impossible to re-fetch the student dashboard content. So, we don't include it in the
        keys, and we make sure that we're defensive enough to be able to render the dashboard even if this is out of sync. See https://trello.com/c/WoZUYG4n.
        If we are not in offline mode and we detect a change here, we will pro-actively re-fetch.

    # RAM caching

    Some users do not have frontRoyalStore enabled. In that case, we don't want to make an API request every time ensureStudentDashboard is called,
    so we also have a RAM cache, implemented through the _promise property.

    Eventually, when everyone is using the frontRoyalStore, we should update this code as follows. Remove the RAM cache and wrap the query to the frontRoyalStore
    in a FrontRoyalStoreAPI endpoint. That way we would not do unnecessary queries to the frontRoyalStore database, but we also wouldn't have the extra
    complexity here of two layers of caching.

*/
import shallowequal from 'shallowequal';
import { type OfflineModeManager } from 'OfflineMode';
import { type IguanaClass } from 'Iguana';
import { type StudentDashboardContentFetch } from 'FrontRoyalStore';
import { type StudentDashboardContentResponse } from 'StudentDashboard';
import getStudentDashboardFromStore from './getStudentDashboardFromStore';
import storeStudentDashboard from './storeStudentDashboard';

type Params = {
    userId: string;
    cohortId?: string;
    cohortVersionId: string;
    prefLocale: string;
    contentViewsRefreshUpdatedAt: number;
    favoriteLessonStreamLocalePackIds: string[];
};

function arrayContentMatches(arr1: string[], arr2: string[]) {
    return shallowequal(arr1.sort(), arr2.sort());
}

export default class StudentDashboardContentProvider {
    private _promise: Promise<{
        response: StudentDashboardContentResponse;
        studentDashboardContentFetch: StudentDashboardContentFetch;
    }> | null = null;

    private _paramsUsedToGeneratePromise: Params | null = null;
    private injector: angular.auto.IInjectorService;

    constructor(injector: angular.auto.IInjectorService) {
        this.injector = injector;
    }

    // These getters are here just to simplify testing
    get promise() {
        return this._promise;
    }

    get paramsUsedToGeneratePromise() {
        return this._paramsUsedToGeneratePromise;
    }

    async getStudentDashboardContent(params: Params): Promise<StudentDashboardContentResponse> {
        // There are 2 levels of caching here. _promise is cached in RAM. This cache is busted whenever
        // getStudentDashboardContent is called with a non-equal set of params. The second level is in
        // the frontRoyalStore, and implemented in getPromise. See the comments at the top of this file
        // about RAM caching for more info on why we have the 2 levels of caching
        if (!this._promise || !this.paramsAreEqual(this._paramsUsedToGeneratePromise!, params)) {
            this._promise = this.getPromise(params);
            this._paramsUsedToGeneratePromise = params;
        }
        const { response, studentDashboardContentFetch } = await this._promise;

        // Once we've pulled the student dashboard content from the frontRoyalStore, we need to make sure that
        // it is still usable. See the notes at the top of the page about the special case of
        // favoriteLessonStreamLocalePackIds for why we need this second step here. If we find that the
        // record from the store is unusable, then we clear out the promise and start over. The second
        // time through, we will refetch the content from the API.
        if (
            !this.studentDashboardContentFetchIsUsable({
                studentDashboardContentFetch,
                params,
            })
        ) {
            this._promise = null;
            return this.getStudentDashboardContent(params);
        }

        return response;
    }

    bustCache() {
        this._promise = null;
        this._paramsUsedToGeneratePromise = null;
    }

    async getPromise(params: Params) {
        const {
            userId,
            cohortId,
            cohortVersionId,
            prefLocale,
            favoriteLessonStreamLocalePackIds,
            contentViewsRefreshUpdatedAt,
        } = params;

        // Check for a record in the frontRoyalStore's studentDashboardContentFetches table. If we find one, then
        // we can use it.
        const { studentDashboardContentFetch, response: storeResponse } =
            await this.getStudentDashboardFromStoreAndCheckIfUsable(params);
        if (storeResponse) {
            return { response: storeResponse, studentDashboardContentFetch: studentDashboardContentFetch! };
        }

        // If we didn't find a record in the store, then fetch it from the api and store
        // a record in frontRoyalStore.
        const apiResponse = await this.fetchStudentDashboardContent(params);
        const newStudentDashboardContentFetch: StudentDashboardContentFetch = {
            user_id: userId,
            cohort_id: cohortId,
            cohort_version_id: cohortVersionId,
            pref_locale: prefLocale,
            favorite_lesson_stream_locale_pack_ids: favoriteLessonStreamLocalePackIds,
            content_views_refresh_updated_at: contentViewsRefreshUpdatedAt,
        };
        await storeStudentDashboard(apiResponse, newStudentDashboardContentFetch, this.injector);
        return {
            response: { result: apiResponse.result },
            studentDashboardContentFetch: newStudentDashboardContentFetch,
        };
    }

    async getStudentDashboardFromStoreAndCheckIfUsable(params: Params): Promise<{
        studentDashboardContentFetch: StudentDashboardContentFetch | null;
        response: StudentDashboardContentResponse | null;
    }> {
        const { userId, cohortId, cohortVersionId, prefLocale, contentViewsRefreshUpdatedAt } = params;
        const result = await getStudentDashboardFromStore(
            { userId, cohortId, cohortVersionId, prefLocale, contentViewsRefreshUpdatedAt },
            this.injector,
        );
        const emptyReturnValue = { studentDashboardContentFetch: null, response: null };

        if (!result) return emptyReturnValue;

        const { studentDashboardContentFetch, response } = result;

        const isUsable = this.studentDashboardContentFetchIsUsable({
            studentDashboardContentFetch,
            params,
        });
        if (!isUsable) return emptyReturnValue;

        return {
            studentDashboardContentFetch,
            response,
        };
    }

    studentDashboardContentFetchIsUsable({
        studentDashboardContentFetch,
        params,
    }: {
        studentDashboardContentFetch: StudentDashboardContentFetch;
        params: Params;
    }) {
        const offlineModeManager = this.injector.get<OfflineModeManager>('offlineModeManager');
        const { favoriteLessonStreamLocalePackIds } = params;

        // If we are in offline mode, we can't make a new api request, so we'll use the record from the store
        // even if it is out of date (see comments at the top of the file for more details on this situation)
        if (offlineModeManager.inOfflineMode) return true;

        if (
            !arrayContentMatches(
                studentDashboardContentFetch.favorite_lesson_stream_locale_pack_ids,
                favoriteLessonStreamLocalePackIds,
            )
        ) {
            return false;
        }

        return true;
    }

    async fetchStudentDashboardContent({ userId, cohortId }: Params) {
        cohortId = cohortId === 'none' ? undefined : cohortId;

        const apiParams = {
            user_id: userId,
            do_not_include_progress: true,
            filters: { cohort_id: cohortId },
        };

        const StudentDashboard = this.injector.get<IguanaClass>('StudentDashboard');
        return StudentDashboard.index<StudentDashboardContentResponse['result']>(apiParams);
    }

    private paramsAreEqual(params1: Params, params2: Params) {
        return Object.entries(params1).reduce((equal, [key, val]) => {
            if (key === 'favoriteLessonStreamLocalePackIds') {
                return (
                    equal &&
                    arrayContentMatches(
                        params1.favoriteLessonStreamLocalePackIds,
                        params2.favoriteLessonStreamLocalePackIds,
                    )
                );
            }

            return equal && val === params2[key as keyof Params];
        }, true);
    }
}
