/* eslint-disable max-classes-per-file */
// Since this is used in gatsby, we rely on the js library instead of the react library

import { type StatsigClient } from '@statsig/js-client';
import delay from 'delay';
import {
    type ErrorLogService as ErrorLogServiceClass,
    LoggableError,
    startSentryInactiveSpan,
    WAIT_FOR_STATSIG_CLIENT_TRACING_NAME,
    WAIT_FOR_STATSIG_LOADED_CLIENT_TRACING_NAME,
} from 'ErrorLogging';
import type * as Sentry from '@sentry/react';
import { angularInjectorProvider } from 'Injector';

class ClientNotSetError extends LoggableError {
    constructor({ caller }: { caller: string }) {
        super('Statsig client not set after waiting 10 seconds.', { caller });
        this.name = 'ClientNotSetError';
    }
}

class StatsigClientProvider {
    private _client: StatsigClient | null = null;
    private clientSetAt: number | null = null;

    get client(): StatsigClient | null {
        return this._client;
    }

    set client(newClient: StatsigClient | null) {
        if (this._client === newClient) return;
        this.clientSetAt = Date.now();
        this._client = newClient;
    }

    get loadedClient(): StatsigClient | null {
        if (this.client?.loadingStatus === 'Ready') return this.client;

        return null;
    }

    get loadingTimedOut(): boolean {
        if (this.client?.loadingStatus === 'Ready') return false;
        if (!this.clientSetAt) return false;
        return Date.now() - this.clientSetAt > 10000;
    }

    // While the angular app is initializing, the react app may not yet have set up
    // the statsigClient. This method will return the client once it is set, before
    // it has loaded data. It should only be used for write operations like updateUserSync.
    // Use waitForGate or waitForHopefullyLoadedClient when you want to read data from statsig.
    async waitForClient({
        timeLeft,
        caller,
        sentrySpan,
    }: {
        timeLeft?: number;
        caller: string;
        sentrySpan?: Sentry.Span;
    }): Promise<StatsigClient> {
        if (timeLeft === undefined) timeLeft = 10000;

        // When multiple places in the code concurrently call waitForGate with the same gate,
        // we will create duplicate spans with the same name. In practice, I don't think this is really
        // gonna matter. The times should still show us if we had to wait a short or long amount of time.
        if (!sentrySpan) {
            sentrySpan = startSentryInactiveSpan([WAIT_FOR_STATSIG_CLIENT_TRACING_NAME, caller].join(':'));
        }

        const client = this.client;
        if (!client && timeLeft > 0) {
            const timeToDelay = 100;
            await delay(timeToDelay);
            return this.waitForClient({ caller, timeLeft: timeLeft - timeToDelay, sentrySpan });
        }

        if (!client) {
            const err = new ClientNotSetError({ caller });

            // notify and throw here might cause the error to be logged twice, but only
            // the one that goes through notify will have the caller on it
            angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService').notify(err);
            throw err;
        }

        sentrySpan.end();
        return client;
    }

    // When the app first loads, the statsig client might not yet have loaded data. We try to wait
    // until it does before using any gates to prevent flashes. After a 10 seconds, though, we'll fall back
    // to using the cached values, so this method does not guarantee that it will return a loaded client
    async waitForHopefullyLoadedClient({
        caller,
        sentrySpan,
    }: {
        timeLeft?: number;
        caller: string;
        sentrySpan?: Sentry.Span;
    }): Promise<StatsigClient> {
        // When multiple places in the code concurrently call waitForGate with the same gate,
        // we will create duplicate spans with the same name. In practice, I don't think this is really
        // gonna matter. The times should still show us if we had to wait a short or long amount of time.
        if (!sentrySpan) {
            sentrySpan = startSentryInactiveSpan([WAIT_FOR_STATSIG_LOADED_CLIENT_TRACING_NAME, caller].join(':'));
        }

        let client = this.client;
        if (!client) {
            client = await this.waitForClient({ caller });
        }

        if (client.loadingStatus === 'Ready') {
            sentrySpan.end();
            return client;
        }

        // If we've been waiting more than 10 seconds for the client to load, just return
        // the uninitialized client
        if (this.loadingTimedOut) {
            sentrySpan.end();
            return client;
        }

        await delay(100);
        return this.waitForHopefullyLoadedClient({ caller, sentrySpan });
    }

    async waitForLoadedClient(): Promise<StatsigClient> {
        if (this.client?.loadingStatus === 'Ready') return this.client;

        await delay(100);
        return this.waitForLoadedClient();
    }

    async waitForGate(gateName: string): Promise<boolean> {
        try {
            const client = await this.waitForHopefullyLoadedClient({ caller: gateName });
            return client.checkGate(gateName);
        } catch (err) {
            // We never expect to get into this catch. We shouldn't have a ClientNotSetError because we wouldn't
            // expect to ever have someone call waitForGate when the client wasn't set yet (It's possible the client
            // hasn't finish loading data, but then we wouldn't be in this catch block). We don't expect any other
            // error because we are excellent engineers. If there is some error, log it and return `false`.
            angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService').notifyInProd(err as Error);
            return false;
        }
    }
}

const statsigClientProvider = new StatsigClientProvider();

export { statsigClientProvider };
