window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.settings = window.locales.am.settings || {};window.locales.am.settings.tuition_and_registration = window.locales.am.settings.tuition_and_registration || {};window.locales.am.settings.tuition_and_registration = {...window.locales.am.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "የእኔን መለጫ እንዲያዩ የክፍል ልጆችን ይፍቀዱላቸው",           
    "header_choose_your_tuition_plan": "የእርስዎን የትምህርት ክፍያ እቅድ ይምረጡ",           
    "header_register": "ለ {{programTitle}} ይመዝገቡ",           
    "narrative_all_paid": "ሙሉ የትምህርት ክፍያዎን ከፍለዋል። አመሰግናለሁ!",           
    "new_browser_register": "ለመመዝገብ፣ የእርስዎን የስርዓት ድር አሳሽ መጠቀም ያስፈልግዎታል።",           
    "new_browser_payment_details": "የክፍያ ዝርዝሮችዎን ለመቀየር፣ የእርስዎ የስርዓት ድር አሳሽ ያስፈልግዎታል።",           
    "confirm_message": "በክፍሉ ውስጥ ያለውን የእርስዎን ቦታ ለማረጋገጥ፣ እባክዎ እስከ <strong>{{registrationDeadline}}</strong>ድረስ ይመዝገቡ።",           
    "confirm_message_past_deadline": "በክፍሉ ውስጥ ያለውን የእርስዎን ቦታ ለማረጋገጥ፣ እባክዎ አሁን ይመዝገቡ።",           
    "register": "ይመዝገቡ",           
    "contact_bursar": "Bursarን ያግኙ",           
    "email_our_bursar": "እንዴት መመዝገብ እንደሚቻል እና በሃዋላ መክፈል እንደሚቻል ላሉ መመሪያዎች ለእኛ Bursar ኢሜይል ያድርጉ።",           
    "launch_browser": "የድር አሳሽን ያስጀምሩ",           
    "registered_contact_support": "ለመለያ መረጃ እና የክፍያ ዝርዝሮች እባክዎ <a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a> ን ያግኙ።",           
    "registered_manage_billing": "የሂሳብ አከፋፈሎችን ያስተዳድሩ።",           
    "registered_manage_billing_payment_trouble": "የክፍያ ዝርዝሮችን አስተካክል",           
    "registered_manage_billing_view_payment_history": "የክፍያ ታሪክን ይመልከቱ",           
    "stripe_name": "{{brandName}} {{fullTitle}}",           
    "stripe_description_monthly_tuition": "ወርሃዊ የትምህርት ክፍያ እቅድ",           
    "stripe_description_annual_tuition": "አመታዊ የትምህርት ክፍያ እቅድ",           
    "stripe_description_once_tuition": "ነጠላ ክፍያ",           
    "stripe_panel_label_pay_tuition": "የትምህርት ክፍያ ይክፈሉ – ",           
    "processing": "በማቀናበር ላይ...",           
    "scholarship_award": "የስኮላርሺፕ ሽልማት",           
    "scholarship_award_with_label": "{{scholarshipLabel}} የስኮላርሺፕ ሽልማት",           
    "please_re_enter_payment_details": "እባክዎ ችግሩን ለመፍታት የክፍያ ዝርዝሮችን ደግመው ያስገቡ።",           
    "currently_enrolled_in_x_billed_at_x_monthly_during": "እርስዎ በአሁኑ ወቅት በ <b>${{paymentPerInterval}} የአሜሪካ ዶላር በወር</b>ክፍያ የሚፈጸመው ላይ ተመዝግበዋል።",           
    "currently_enrolled_in_x_billed_at_x_monthly": "እርስዎ በአሁኑ ወቅት በ <b>${{paymentPerInterval}} የአሜሪካ ዶላር በወር</b>ክፍያ የሚፈጸመው ላይ ተመዝግበዋል።",           
    "currently_enrolled_in_x_billed_at_x_annual_during": "እርስዎ በአሁኑ የትምህርት ቆይታዎ ወቅት በ <b>${{paymentPerInterval}} የአሜሪካ ዶላር </b> በአመት ውስጥ ሁለቴ የሚቆረጥ የትምህርት ክፍያ እቅድ ላይ ተመዝግበዋል።",           
    "currently_enrolled_in_x_billed_at_x_annual": "እርስዎ በአሁኑ ወቅት በ <b>በአመት ውስጥ ሁለቴ${{paymentPerInterval}} የአሜሪካ ዶላር</b> የሚቆረጥ የትምህርት ክፍያ እቅድ ላይ ተመዝግበዋል።",           
    "confirm_message_before_deadline": "ምዝገባ የሚከፈተው በ<br class=\"visible-xs\" /> <strong>{{registrationOpen}}</strong>።",           
    "choose_your_plan": "የእርስዎን የትምህርት ክፍያ እቅድ ይምረጡ፤",           
    "available_plans": "የሚገኙ የትምህርት ክፍያ እቅዶች፤",           
    "explain_the_options": "አምራጮችን ይግለጹ",           
    "choose_tuition": "ለእርስዎ የሚስማማ የትምህርት ክፍያ አማራጭ ይምረጡ",           
    "option_one": "አማራጭ 1",           
    "option_two": "አማራጭ 2",           
    "option_three": "አማራጭ 3",           
    "which_section_is_right": "የትኛው ክፍል ለእርስዎ ትክክለኛ ነው?",           
    "two_different_cohort_sections": "ሁለት የተለያዩ ተጠኝ ክፍሎችን ባቀረብንበት ክልል ውስጥ ይኖራሉ።",           
    "global_section_explanation": "የእኛ <b>አለም አቀፍ ክፍል</b> በቡድን ፕሮጀክቶች እና መልመጃዎች ውስጥ ከተጨማሪ አለም አቀፍ አመለካከቶች ጋር ከ 30 ሃገራት በላይ የተውጣጡ ተማሪዎችን ይዟል። ከግማሽ በላይ የሆኑ የክፍል ተማሪዎች በሰሜን አሜርካ እና በምእራብ አውሮፓ ይኖራሉ።",           
    "both_sections_same_explanation": "ሁለቱም ክፍሎች አንድ አይነት Quantic ዲግሪ በማግኘት ተመሳሳይ የካሪኩለም መሳሪያዎች፣ ፕሮጀክቶች፣ እና የኬዝ ጥናቶችን ይጠቀማሉ። ሁለቱም የእኛን አማራጭ የአካል ክስተቶች እንዲሁም የሙሉ አለም አቀፍ የቀድሞ ተማሪዎች ትስስር መዳረሻ አላቸው።",           
    "choose_your_section": "የስኮላርሺፕ እና የትምህርት ወጪ አማራጮችን ለመገምገም የእርስዎን ተጠኝ ክፍል ይምረጡ።",           
    "whats_the_difference": "ልዩነቱ ምንድን ነው?",           
    "global_section": "አለም አቀፍ ክፍል",           
    "regional_section": "የደቡብ/ የደቡብ ምስራቅ እስያ ክፍል",           
    "global_section_subtext": "ከ 30 በላይ ሃገራት ከተውጣጡ ተማሪዎች ጋር ይማሩ እንዲሁም በጋራ ይስሩ።",           
    "regional_section_subtext": "ከክልል ተማሪዎች ጋር ብቻ ይማሩ እንዲሁም በጋራ ይስሩ። የ Greater ስኮላርሺፕ የትምህርት ወጪን ይቀንሳል።",           
    "regional_section_subtext_full_scholarship": "ከክልል ተማሪዎች ጋር ብቻ ይማሩ እንዲሁም በጋራ ይስሩ።",           
    "registration_deadline": "የመመዝገቢያ የመጨረሻ ቀን <br class=\"visible-xs\" /> {{registrationDeadline}} ነው።<br class=\"visible-xs\" /> <div class=\"visible-xs\" style=\"padding-top: 10px;\"></div> ትምህርት በ {{classStartDate}} ይጀምራል።",           
    "regional_section_explanation": "የእኛ <b>{{regionalSectionName}}</b> ከዚህ ክልል ብቻ የመጡ ተማሪዎችን ይዟል ይህም በድርጅት ስፖንሰርሺፕ እና በተጨመሩ የስኮላርሺፕ ሽልማቶች በኩል ዝቅተኛ የትምህርት ክፍያዎችን በማቅረብ ከፍ ያለ አካባቢያዊ ግንኙነቶች እንዲኖሩ ማስቻል ነው።",           
    "action_required": "እርምጃ ያስፈልጋል",           
    "confirm_payment": "የእርስዎን የቅርብ ጊዜ የ ${{paymentPerInterval}} የአሜሪካ ዶላር ክፍያ ለማረጋገጥ ኢሜይል ልከንልዎታል።",           
    "additional_steps_required": "እርስዎን ከማጭበርበር ለመጠበቅ፣ የመስመር ላይ ግብይቶች ፈቃድ እንዳገኙ ለማረጋገጥ ባክንክዎ ተጨማሪ እርምጃ ይጠይቃል። ምዝገባዎን ለመጨረስ Quantic ላይ ክፍያዎን እባክዎ ያረጋግጡ።",           
    "plan_description_monthly_partial_scholarship": "በወር ከ$ {{paymentPerIntervalForFullTuition}} ቀንሷል።",
    "payment_options": "የክፍያ አማራጮች",
    "make_monthly_payments": "በፕሮግራሙ ቆይታ ጊዜ {{numIntervals}} ምቹ ወርሃዊ ክፍያዎችን ያድርጉ።",
    "refund_policy": "የእኛ ለጋስ <a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">የተመላሽ ገንዘብ ፖሊሲ</a> ትምህርት ከመጀመሩ በፊት ለሚደረጉ ክፍያዎች ሙሉ ገንዘብ መመለስን ያካትታል።",
    "deferring_admission": "መግቢያን በማዘግየት ላይ",
    "wiring_funds": "የወልና ፈንዶች",
    "our_bank_heading": "የእኛ ባንክ",
    "our_account_name_heading": "የእኛ መለያ ስም",
    "our_account_number_heading": "የእኛ መለያ ቁጥር",
    "for_us_wires_heading": "ለ US Wires",
    "for_international_wires_heading": "ለአለም አቀፍ ሽቦዎች",
    "identifier": "መለያ፡ የተማሪ እና የላኪ ስም እና አድራሻ ሙሉ ስም",
    "monthly_option": "ወርሃዊ አማራጭ",
    "annual_option": "2-የክፍያ አማራጭ",
    "upfront_option": "1-የክፍያ አማራጭ",
    "review_and_register": "ይገምግሙ እና ይመዝገቡ",
    "contact_us_with_preferred_start_date": "አንዴ ከተመዘገቡ በኋላ በቀላሉ በመረጡት ቀን በ {{admissionsEmail}} ።",
    "contact_billing_with_questions": "ክፍያ ከጀመርክ በኋላ፣ ምዝገባህን ለማረጋገጥ {{billingEmail}} ን አግኝ።",
    "bank_details_intro": "ባለ 1-ክፍያ ወይም 2-ክፍያ አማራጮችን ከመረጡ ለባንክ ወይም ለገንዘብ ማስተላለፊያ አገልግሎት የሚከተሉትን ዝርዝሮች ያቅርቡ። ለወርሃዊ የክፍያ አማራጭ ሽቦዎችን አንቀበልም።",
    "choose_different_start_date": "በ {{startDate}} የሚጀምረውን የአሁኑን ቡድን መቀላቀል ካልቻላችሁ፣ አሁን በመመዝገብ እና ከሚከተሉት የህብረት ጅምር ቀናት ውስጥ አንዱን በመምረጥ አወንታዊ ምዝገባዎን ማቆየት ይችላሉ።",
    "choose_different_start_date_scholarship": "በ {{startDate}} ላይ የሚጀምረውን የአሁኑን ቡድን መቀላቀል ካልቻላችሁ፣ አሁን በመመዝገብ እና ከሚከተሉት የህብረት ጅምር ቀናት ውስጥ አንዱን በመምረጥ አዎንታዊ የመግቢያ እና የስኮላርሺፕ ሽልማትን ማቆየት ይችላሉ።",
    "reapply_at_different_time": "መመዝገብ ካልቻሉ በ {{reapplicationMonthYear}} ውስጥ እንደገና ማመልከት ይችላሉ። በዚህ ጉዳይ ላይ ወደፊት ለመግባት ዋስትና መስጠት አንችልም።",
    "reapply_at_different_time_scholarship": "መመዝገብ ካልቻሉ በ {{reapplicationMonthYear}} ውስጥ እንደገና ማመልከት ይችላሉ። በዚህ ጉዳይ ላይ የወደፊት ቅበላ ወይም ተመሳሳይ የትምህርት እድል ዋስትና መስጠት አንችልም።",
    "reapply_at_different_time_no_date": "መመዝገብ ካልቻሉ፣ ሌላ ጊዜ እንደገና ማመልከት ይችላሉ። በዚህ ጉዳይ ላይ ወደፊት ለመግባት ዋስትና መስጠት አንችልም።",
    "reapply_at_different_time_scholarship_no_date": "መመዝገብ ካልቻሉ፣ ሌላ ጊዜ እንደገና ማመልከት ይችላሉ። በዚህ ጉዳይ ላይ የወደፊት ቅበላ ወይም ተመሳሳይ የትምህርት እድል ዋስትና መስጠት አንችልም።",
    "for_us_wires_content": "የማዞሪያ እና የመጓጓዣ ቁጥር {{transitNumber}}",
    "quantic_mailing_address_heading": "{{institutionName}} የፖስታ አድራሻ",
    "registered_processing_payment": "ክፍያዎን እየሰራን ነው።",
    "trouble_processing_payment": "ክፍያዎን ለማስኬድ እየተቸገርን ነው።",
    "registered_payment_smallprint_once": "በጥናት ወቅት አንድ ጊዜ ${{paymentPerInterval}} እንዲከፍሉ ይደረጋሉ።",
    "plan_description_once": "$ {{additionalSavings}} ተጨማሪ ቁጠባዎችን ያካትታል።",
    "plan_description_annual": "$ {{additionalSavings}} ተጨማሪ ቁጠባዎችን ያካትታል።",
    "plan_description_monthly": "በጣም ምቹ የክፍያ አማራጭ።",
    "plan_description_once_partial_scholarship": "ከ$ {{paymentPerIntervalForFullTuition}} ። $ {{additionalSavings}} ተጨማሪ ቁጠባዎችን ያካትታል።",
    "plan_description_annual_partial_scholarship": "ከ$ {{paymentPerIntervalForFullTuition}} ። $ {{additionalSavings}} ተጨማሪ ቁጠባዎችን ያካትታል።",
    "make_single_payment": "አንድ የቅድሚያ ክፍያ ይፈጽሙ እና ከጠቅላላ ትምህርትዎ $ {{additionalSavings}} ይቆጥቡ።",
    "make_two_payments": "አሁን ግማሹን እና ከአንድ አመት በኋላ ይክፈሉ እና ከጠቅላላ ትምህርትዎ $ {{additionalSavings}} ይቆጥቡ። በ2 የቀን መቁጠሪያ ዓመታት ውስጥ የሚደረጉ ክፍያዎች ተማሪዎች ከድርጅታቸው የሚገኘውን የትምህርት ጥቅማጥቅሞችን ከፍ ለማድረግ ይረዳሉ።",
    "narrative_no_scholarship": "እንኳን ወደ ፕሮግራሙ በደህና መጡ! እባክዎ የክፍያ አማራጮችን ይከልሱ እና ከዚህ በታች ይመዝገቡ።",
    "narrative_full_scholarship": "ለፕሮግራሙ ሙሉ ስኮላርሺፕ ተሸልመዋል። በዚህ ስኬት እንኳን ደስ አለዎት!",
    "graphic_full_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "registration_deadline_mobile": "የምዝገባ ማብቂያ ቀን {{registrationDeadline}}.<div style=\"padding-top: 7px;\"> ክፍል የሚጀምረው በ {{classStartDate}} ነው።</div>",
    "tuition_details": "የትምህርት ዝርዝሮች",
    "tuition_and_scholarship_details": "የትምህርት እና የስኮላርሺፕ ዝርዝሮች",
    "graphic_no_scholarship_title": "<b>እንኳን ደስ አላችሁ</b>",
    "graphic_no_scholarship_subtitle": "ለመመዝገብ ይመዝገቡ",
    "graphic_partial_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "base_tuition_no_scholarship": "{{programTitle}} ትምህርት",
    "base_tuition": "ቤዝ {{programTitle}} ትምህርት",
    "total_amount": "(ለ {{numIntervals}} ወሮች = $ {{totalAmount}} USD)",
    "total_tuition": "ጠቅላላ ክፍያ",
    "full_scholarship": "(ሙሉ ስኮላርሺፕ)",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} USD</span>",
    "discount_of_ledger_item": "– $ <span class=\"value\">{{value}} USD</span>",
    "select_payment_method": "የመክፈያ ዘዴን ይምረጡ",
    "back_to_plan_selection": "ወደ እቅድ ምርጫ ተመለስ",
    "button_view_wire_transfer_instructions": "መመሪያዎችን ይመልከቱ",
    "us_bank_account": "የአሜሪካ ባንክ መለያ",
    "card": "ካርድ",
    "wire_transfer": "የሃዋላ ገንዘብ መላኪያ",
    "transaction_fee": "{{creditCardPaymentFeeRate}}% የግብይት ክፍያ",
    "smallprint_transaction_fee": "ለዴቢት ካርዶች ወይም ለቅድመ ክፍያ ካርዶች ክፍያ አይተገበርም። የዴቢት/የቅድመ ክፍያ ካርዶችን በመጠቀም የሚደረጉ ክፍያዎች ክፍያው ከተጠናቀቀ በኋላ ወዲያውኑ ተመላሽ ይደረጋል።",
    "selected_tuition_plan_once": "የተመረጠ የትምህርት እቅድ፡ ${{paymentAmountPerInterval}} ዶላር አንድ ጊዜ ተከፍሏል።",
    "selected_tuition_plan_annual": "የተመረጠ የትምህርት እቅድ፡ ${{paymentAmountPerInterval}} ዶላር ሁለት ጊዜ ተከፍሏል።",
    "selected_tuition_plan_monthly": "የተመረጠ የትምህርት እቅድ፡ ${{paymentAmountPerInterval}} ዶላር በወር",
    "tuition_plan_detail_once": "ለአንድ ቅድመ ክፍያ ${{netDiscountAmount}} ቁጠባ።<br /> (ዝቅተኛው የትምህርት አማራጭ)",
    "tuition_plan_detail_monthly": "በፕሮግራሙ ሂደት ውስጥ {{numIntervals}} ክፍያዎች።<br /> (በጣም ምቹ አማራጭ)",
    "single_payment_plan_desc": "${{paymentPerInterval}} ዶላር አንድ ጊዜ ተከፍሏል።",
    "annual_plan_desc": "${{paymentPerInterval}} ዶላር ሁለት ጊዜ ተከፍሏል።",
    "monthly_plan_desc": "${{paymentPerInterval}} ዶላር በወር",
    "currently_enrolled_in_x_billed_at_x_once": "",
    "paid_x_of_x": "ከ$ {{netAmountPaid}} ዶላር $ {{netRequiredPayment}} ከፍለዋል።",
    "narrative_partial_scholarship": "አጠቃላይ ትምህርትህን በመቀነስ የሚከተለውን {num, plural, =1{ ስኮላርሺፕ } other{ ስኮላርሺፕ }} ተሸልመሃል። እንኳን ደስ አላችሁ!",
    "graphic_partial_scholarship_subtitle": "ጠቅላላ {num, plural, =1{ ስኮላርሺፕ } other{ ስኮላርሺፕ }} ተሸልሟል",
    "continue_to_register": "መመዝገብዎን ይቀጥሉ",
    "graphic_full_scholarship_subtitle": "ጠቅላላ ስኮላርሺፕ ተሸልሟል",
    "registered_payment_smallprint_with_fee_once": "በጥናት ወቅት አንድ ጊዜ $ {{paymentPerInterval}} እንዲከፍሉ ይደረጋሉ። በካርድ ግብይት ላይ ተጨማሪ ክፍያ ሊከፈል ይችላል።",
    "prefer_later_start_date": "{{upcomingClassStartDate}} መጀመሪያ ቀን ከመረጡ <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> ይመዝገቡ እና ያነጋግሩ።",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;\">ከመረጡ ይመዝገቡ እና ኢሜይል ያድርጉልን<br /> {{upcomingClassStartDate}} የመጀመሪያ ቀን።</div>",
    "base_tuition_no_program_title": "ቤዝ ትምህርት",
    "back": "ተመለስ",
    "bank_details_intro_1_pay_only": "የእርስዎን የባንክ ወይም የገንዘብ ማስተላለፊያ አገልግሎት በሚከተሉት ዝርዝሮች ያቅርቡ፡",
    "buy_now_pay_later": "በኋላ ይክፈሉ።",
    "upfront_and_save": "አስቀድመው ይክፈሉ እና <span class=\"green-text\">ተጨማሪ $ {{netDiscountAmount}} ያስቀምጡ<span/>",
    "twice_and_save": "ሁለት ጊዜ ይክፈሉ እና <span class=\"green-text\">ተጨማሪ $ {{netDiscountAmount}} ያስቀምጡ<span/>",
    "pay_monthly": "ለእርስዎ ምቾት በየወሩ ይክፈሉ።",
    "tuition_plan_detail_b_once": "አጠቃላይ የትምህርት ክፍያን ከ$ {{baseTuition}} ወደ $ {{paymentPerInterval}} ዶላር ይቀንሳል<br /> የስኮላርሺፕ እና የእቅድ ቅናሽን ጨምሮ",
    "tuition_plan_detail_b_annual": "$ {{paymentPerInterval}} ዶላር በዓመት ልዩነት ሁለት ጊዜ ተከፍሎል።",
    "tuition_plan_detail_b_monthly": "$ {{paymentPerInterval}} ዶላር በወር ለ {{numIntervals}} ወራት",
    "student_preferred_plan": "የተማሪ ተመራጭ እቅድ",
    "registered_payment_smallprint_recurring": "ቀጣዩ ክፍያዎ <strong>$ {{paymentPerInterval}}</strong> ለ <strong>{{nextPaymentDate}}</strong> መርሐግብር ተይዞለታል።",
    "registered_payment_smallprint_with_fee_recurring": "ቀጣዩ ክፍያዎ <strong>$ {{paymentPerInterval}}</strong> ለ <strong>{{nextPaymentDate}}</strong> መርሐግብር ተይዞለታል። በካርድ ግብይት ላይ ተጨማሪ ክፍያ ሊከፈል ይችላል።",
    "notify_nominator": "{{nominatorName}} መልካሙን ዜና ይወቅ!",
    "smallprint": "ተማሪዎች መገኘት የማይችሉባቸው አልፎ አልፎ እንረዳለን። በዚህ አጋጣሚ አሁን በመመዝገብ እና <a ng-click=\"openDeferralModal()\">ለወደፊት ክፍል በማስተላለፍ</a> መግቢያዎን ማቆየት ይችላሉ።",
    "smallprint_no_reapplication_date": "ተማሪዎች መገኘት የማይችሉባቸው አልፎ አልፎ እንረዳለን። በዚህ አጋጣሚ አሁኑኑ በመመዝገብ እና <a ng-click=\"openDeferralModal()\">ለወደፊት ክፍል በማስተላለፍ</a> መግቢያዎን ማቆየት ይችላሉ።",
    "smallprint_scholarship": "ተማሪዎች መገኘት የማይችሉባቸው አልፎ አልፎ እንረዳለን። በዚህ አጋጣሚ አሁን በመመዝገብ እና <a ng-click=\"openDeferralModal()\">ለወደፊት ክፍል በማስተላለፍ</a> የመግቢያ እና የስኮላርሺፕ ትምህርትዎን ማቆየት ይችላሉ።",
    "smallprint_scholarship_no_reapplication_date": "ተማሪዎች መገኘት የማይችሉባቸው አልፎ አልፎ እንረዳለን። በዚህ አጋጣሚ አሁን በመመዝገብ እና <a ng-click=\"openDeferralModal()\">ለወደፊት ክፍል በማስተላለፍ</a> የመግቢያ እና የስኮላርሺፕ ትምህርትዎን ማቆየት ይችላሉ።",
    "tuition_plan_detail_annual": "$ {{netDiscountAmount}} ቁጠባ ለ2 ክፍያዎች፣ በአንድ ዓመት ልዩነት።<br /> (ይበልጥ ተለዋዋጭ አማራጭ)",
    "make_single_payment_partial_scholarship": "አንድ የቅድሚያ ክፍያ ይፈጽሙ እና ከጠቅላላ ትምህርትዎ $ {{additionalSavings}} ይቆጥቡ።",
    "make_two_payments_partial_scholarship": "ግማሽ አሁን እና ከግማሽ ዓመት በኋላ ይክፈሉ እና ከጠቅላላ ትምህርትዎ $ {{additionalSavings}} ይቆጥቡ። በ2 የቀን መቁጠሪያ ዓመታት ውስጥ የሚደረጉ ክፍያዎች ተማሪዎች ከድርጅታቸው የሚገኘውን የትምህርት ጥቅማጥቅሞችን ከፍ ለማድረግ ይረዳሉ።",
    "registration_deadline_no_start_date": "የመመዝገቢያ ቀነ-ገደብ {{registrationDeadline}} ነው።",
    "registration_deadline_mobile_no_start_date": "የምዝገባ ማብቂያ ቀን {{registrationDeadline}} .",
    "choose_start_date": "የክፍል መጀመሪያ ቀን ይምረጡ፡",
    "choose_either_cohort": "እንደ ቀደምት የመግቢያ ጥቅም ፣<br /> አንዱን ቡድን መምረጥ ትችላለህ።",
    "funding_options": "ለተጨማሪ የፋይናንስ አማራጮች <a href=\"mailto:{{fundingEmail}}\" class=\"text-primary\">{{fundingEmail} }</a> ን ያነጋግሩ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.settings = window.locales.ar.settings || {};window.locales.ar.settings.tuition_and_registration = window.locales.ar.settings.tuition_and_registration || {};window.locales.ar.settings.tuition_and_registration = {...window.locales.ar.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "السماح لزملاء الدراسة بمشاهدة ملفي الشخصي",
    "header_choose_your_tuition_plan": "اختر خطتك الدراسية",
    "new_browser_register": "للتسجيل ، ستحتاج إلى استخدام متصفح الويب الخاص بنظامك.",
    "new_browser_payment_details": "لتعديل تفاصيل الدفع الخاصة بك ، سوف تحتاج إلى استخدام متصفح الويب الخاص بنظامك.",
    "confirm_message": "لتأكيد مكانك في الفصل ، يرجى التسجيل قبل <strong>{{registrationDeadline}}</strong>.",
    "confirm_message_past_deadline": "لتأكيد مكانك في الفصل ، يرجى التسجيل الآن.",
    "register": "تسجيل",
    "launch_browser": "قم بتشغيل متصفح الويب",
    "stripe_description_monthly_tuition": "الخطة الدراسية الشهرية",
    "stripe_description_annual_tuition": "الخطة الدراسية السنوية",
    "stripe_description_once_tuition": "دفع مرة واحدة",
    "stripe_panel_label_pay_tuition": "دروس في الأجور -",
    "processing": "معالجة ...",
    "scholarship_award": "جائزة المنحة",
    "please_re_enter_payment_details": "الرجاء إعادة إدخال تفاصيل الدفع لحل المشكلة.",
    "currently_enrolled_in_x_billed_at_x_monthly_during": "أنت مسجل حاليًا في الخطة الدراسية الشهرية التي يتم دفع فاتورتها <b>بمبلغ $ {{paymentPerInterval}} دولار أمريكي شهريًا</b>.",
    "currently_enrolled_in_x_billed_at_x_monthly": "أنت مسجل حاليًا في الخطة الدراسية الشهرية التي يتم دفع فاتورتها <b>بمبلغ $ {{paymentPerInterval}} دولار أمريكي شهريًا</b>.",
    "currently_enrolled_in_x_billed_at_x_annual_during": "أنت مسجل حاليًا في الخطة الدراسية السنوية التي يتم دفع فاتورة بها <b>مرتين بمبلغ $ {{paymentPerInterval}} دولار أمريكي</b> أثناء فترة الدراسة.",
    "currently_enrolled_in_x_billed_at_x_annual": "أنت مسجل حاليًا في الخطة الدراسية السنوية التي يتم دفع فواتيرها <b>مرتين بمبلغ $ {{paymentPerInterval}} دولار أمريكي</b>.",
    "confirm_message_before_deadline": "يفتح التسجيل في <strong>{{registrationOpen}}</strong>.",
    "choose_your_plan": "اختر خطتك الدراسية",
    "explain_the_options": "شرح الخيارات",
    "choose_tuition": "اختر خيار التعليم المناسب لك",
    "option_one": "الخيار 1",
    "option_two": "الخيار 2",
    "option_three": "الخيار 3",
    "header_register": "التسجيل في {{programTitle}}",
    "stripe_name": "{{brandName}} {{fullTitle}}",
    "scholarship_award_with_label": "{{scholarshipLabel}} جائزة المنح الدراسية",
    "registered_contact_support": "يرجى الاتصال بـ <a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a> للحصول على معلومات الحساب وإيصالات الدفع.",
    "registered_manage_billing": "إدارة الفواتير",
    "registered_manage_billing_payment_trouble": "إصلاح تفاصيل الدفع",
    "registered_manage_billing_view_payment_history": "عرض تاريخ الدفع",
    "contact_bursar": "الاتصال بـ Bursar",
    "email_our_bursar": "أرسل بريدًا إلكترونيًا إلى Bursar للحصول على إرشادات حول كيفية التسجيل والدفع عن طريق التحويل المصرفي.",
    "available_plans": "الخطط الدراسية المتاحة",
    "which_section_is_right": "أي قسم مناسب لك؟",
    "two_different_cohort_sections": "أنت تعيش في منطقة نقدم فيها قسمين مختلفين للمجموعة.",
    "global_section_explanation": "<b>يضم القسم العالمي</b> لدينا طلابًا من أكثر من 30 دولة ، مع المزيد من وجهات النظر الدولية في المشاريع والتمارين الجماعية. يعيش أكثر من نصف الفصل في أمريكا الشمالية وأوروبا الغربية.",
    "both_sections_same_explanation": "يستخدم كلا القسمين نفس مواد المنهج الدراسي والمشاريع ودراسات الحالة ، بينما يحصلان على نفس درجة Quantic. يتمتع كلاهما بإمكانية الوصول إلى الأحداث الشخصية الاختيارية بالإضافة إلى شبكة الخريجين العالمية الكاملة.",
    "choose_your_section": "اختر قسم الفوج الخاص بك لمراجعة خيارات المنح الدراسية والتعليم.",
    "whats_the_difference": "ماهو الفرق؟",
    "global_section": "القسم العالمي",
    "regional_section": "S / SE قسم آسيا",
    "global_section_subtext": "ادرس وتعاون مع طلاب من أكثر من 30 دولة.",
    "regional_section_subtext": "الدراسة والتعاون مع زملاء الدراسة الإقليميين فقط. المنح الدراسية الأكبر تقلل الرسوم الدراسية.",
    "regional_section_subtext_full_scholarship": "الدراسة والتعاون مع زملاء الدراسة الإقليميين فقط.",
    "registration_deadline": "الموعد النهائي للتسجيل هو<br class=\"visible-xs\" /> {{registrationDeadline}}.<br class=\"visible-xs\" /><div class=\"visible-xs\" style=\"padding-top: 10px;;text-align:right;direction:rtl\"></div> يبدأ الفصل في {{classStartDate}}.",
    "regional_section_explanation": "<b>{{regionalSectionName}}</b> طلابًا فقط من هذه المنطقة ، مما يتيح قدرًا أكبر من الشبكات المحلية مع تقديم تكلفة تعليم أقل من خلال رعاية الشركات وجوائز المنح الدراسية المتزايدة.",
    "action_required": "الإجراء مطلوب",
    "confirm_payment": "لقد أرسلنا إليك بريدًا إلكترونيًا لتأكيد {{paymentPerInterval}} بالدولار الأمريكي.",
    "additional_steps_required": "لحمايتك من الاحتيال ، يطلب البنك الذي تتعامل معه خطوة إضافية لضمان اعتماد المعاملات عبر الإنترنت. يرجى تأكيد الدفع الخاص بك إلى Quantic لإكمال التسجيل الخاص بك.",
    "plan_description_monthly_partial_scholarship": "تم التخفيض من {{paymentPerIntervalForFullTuition}} دولارًا أمريكيًا في الشهر.",
    "payment_options": "خيارات الدفع",
    "make_monthly_payments": "قم بإجراء دفعات شهرية مريحة {{numIntervals}} طوال مدة البرنامج.",
    "refund_policy": "تتضمن <a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">سياسة الاسترداد</a> السخية الخاصة بنا استردادًا كاملاً لأي مدفوعات قبل بدء الفصول الدراسية.",
    "deferring_admission": "تأجيل القبول",
    "wiring_funds": "صناديق الأسلاك",
    "our_bank_heading": "بنكنا",
    "our_account_name_heading": "اسم حسابنا",
    "our_account_number_heading": "رقم حسابنا",
    "for_us_wires_heading": "لأسلاك الولايات المتحدة",
    "for_international_wires_heading": "للاسلاك الدولية",
    "identifier": "المعرف: الاسم الكامل للطالب واسم المرسل وعنوانه",
    "monthly_option": "الخيار الشهري",
    "annual_option": "2-خيار الدفع",
    "upfront_option": "1-خيار الدفع",
    "review_and_register": "مراجعة وتسجيل",
    "contact_us_with_preferred_start_date": "بمجرد التسجيل ، ما عليك سوى التواصل معنا بتاريخك المفضل على {{admissionsEmail}}.",
    "contact_billing_with_questions": "بعد بدء الدفع ، اتصل بـ {{billingEmail}} لتأكيد التسجيل.",
    "bank_details_intro": "قدِّم إلى المصرف الذي تتعامل معه أو خدمة التحويل الإلكتروني التفاصيل التالية في حالة اختيار خيار الدفع الواحد أو خيار الدفع الثاني. نحن لا نقبل الأسلاك لخيار الفوترة الشهرية.",
    "choose_different_start_date": "إذا لم تتمكن من الانضمام إلى المجموعة النموذجية الحالية التي تبدأ في {{startDate}} ، فيمكنك الحفاظ على قبولك الإيجابي بالتسجيل الآن واختيار أحد تواريخ بدء المجموعة النموذجية التالية:",
    "choose_different_start_date_scholarship": "إذا لم تتمكن من الانضمام إلى المجموعة النموذجية التي تبدأ في {{startDate}} ، فيمكنك الحفاظ على قبولك الإيجابي وجائزة المنحة الدراسية بالتسجيل الآن واختيار أحد تواريخ بدء المجموعة التالية:",
    "reapply_at_different_time": "إذا كنت غير قادر على التسجيل ، فيمكنك إعادة التقديم في {{reapplicationMonthYear}}. نحن غير قادرين على ضمان القبول في المستقبل في هذه الحالة.",
    "reapply_at_different_time_scholarship": "إذا كنت غير قادر على التسجيل ، فيمكنك إعادة التقديم في {{reapplicationMonthYear}}. لا يمكننا ضمان القبول المستقبلي أو نفس المنحة في هذه الحالة.",
    "reapply_at_different_time_no_date": "إذا كنت غير قادر على التسجيل ، يمكنك إعادة التقديم في وقت لاحق. نحن غير قادرين على ضمان القبول في المستقبل في هذه الحالة.",
    "reapply_at_different_time_scholarship_no_date": "إذا كنت غير قادر على التسجيل ، يمكنك إعادة التقديم في وقت لاحق. لا يمكننا ضمان القبول المستقبلي أو نفس المنحة في هذه الحالة.",
    "for_us_wires_content": "رقم التوجيه والعبور: {{transitNumber}}",
    "quantic_mailing_address_heading": "العنوان البريدي {{institutionName}}",
    "registered_processing_payment": "نحن نعالج دفعتك.",
    "trouble_processing_payment": "نواجه مشكلة في معالجة دفعتك.",
    "registered_payment_smallprint_once": "سيتم تحصيل مبلغ {{paymentPerInterval}} دولارًا أمريكيًا مرة واحدة أثناء الدورة الدراسية.",
    "plan_description_once": "يتضمن مدخرات إضافية $ {{additionalSavings}}.",
    "plan_description_annual": "يتضمن مدخرات إضافية $ {{additionalSavings}}.",
    "plan_description_monthly": "خيار الفوترة الأكثر ملاءمة.",
    "plan_description_once_partial_scholarship": "مخفضة من $ {{paymentPerIntervalForFullTuition}}. يتضمن مدخرات إضافية $ {{additionalSavings}}.",
    "plan_description_annual_partial_scholarship": "مخفضة من $ {{paymentPerIntervalForFullTuition}}. يتضمن مدخرات إضافية $ {{additionalSavings}}.",
    "make_single_payment": "سدد دفعة مقدمة واحدة ووفر $ {{additionalSavings}} من إجمالي الرسوم الدراسية.",
    "make_two_payments": "ادفع نصف المبلغ الآن ونصف العام بعد ذلك ووفر $ {{additionalSavings}} من إجمالي الرسوم الدراسية. يمكن للمدفوعات في سنتين تقويميتين مساعدة الطلاب على زيادة الفوائد التعليمية من شركتهم.",
    "narrative_all_paid": "لقد دفعت الرسوم الدراسية بالكامل. شكرًا لك!",
    "narrative_no_scholarship": "مرحبًا بك في البرنامج! يرجى مراجعة خيارات الدفع والتسجيل أدناه.",
    "narrative_full_scholarship": "لقد حصلت على منحة دراسية كاملة للبرنامج. مبروك على هذا الانجاز!",
    "graphic_full_scholarship_title": "<strong>{{totalScholarship}} دولار</strong> أمريكي",
    "registration_deadline_mobile": "آخر موعد للتسجيل: {{registrationDeadline}}.<div style=\"padding-top: 7px;;text-align:right;direction:rtl\"> يبدأ الفصل في {{classStartDate}}.</div>",
    "tuition_details": "تفاصيل الرسوم الدراسية",
    "tuition_and_scholarship_details": "تفاصيل المنحة الدراسية",
    "graphic_no_scholarship_title": "<b>تهانينا</b>",
    "graphic_no_scholarship_subtitle": "سجل للتسجيل",
    "graphic_partial_scholarship_title": "<strong>{{totalScholarship}} دولار</strong> أمريكي",
    "base_tuition_no_scholarship": "{{programTitle}} الرسوم الدراسية",
    "base_tuition": "دروس {{programTitle}} الأساسية",
    "total_amount": "(لمدة {{numIntervals}} شهر = $ {{totalAmount}} دولار أمريكي)",
    "total_tuition": "مجموع الرسوم الدراسية",
    "full_scholarship": "(منحة دراسية كاملة)",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} دولار أمريكي</span>",
    "discount_of_ledger_item": "- $ <span class=\"value\">{{value}} دولار أمريكي</span>",
    "select_payment_method": "اختار طريقة الدفع",
    "back_to_plan_selection": "العودة إلى تحديد الخطة",
    "button_view_wire_transfer_instructions": "عرض التعليمات",
    "us_bank_account": "حساب بنكي أمريكي",
    "card": "بطاقة",
    "wire_transfer": "تحويلة كهربية بالسلك",
    "transaction_fee": "{{creditCardPaymentFeeRate}} ٪ رسوم المعاملة",
    "smallprint_transaction_fee": "لا تنطبق الرسوم على بطاقات الخصم أو البطاقات مسبقة الدفع. المدفوعات باستخدام بطاقات الخصم / الدفع المسبق سيتم استرداد الرسوم فورًا بعد الدفع.",
    "selected_tuition_plan_once": "الخطة الدراسية المحددة: {{paymentAmountPerInterval}} دولار أمريكي يتم تحصيلها مرة واحدة",
    "selected_tuition_plan_annual": "الخطة الدراسية المحددة: {{paymentAmountPerInterval}} دولار أمريكي تم تحصيلها مرتين",
    "selected_tuition_plan_monthly": "الخطة الدراسية المحددة: {{paymentAmountPerInterval}} دولار أمريكي شهريًا",
    "tuition_plan_detail_once": "توفير {{netDiscountAmount}} دولار أمريكي لدفعة مقدمة واحدة.<br /> (أقل خيار للرسوم الدراسية)",
    "tuition_plan_detail_monthly": "{{numIntervals}} من المدفوعات على مدار البرنامج.<br /> (الخيار الأكثر ملاءمة)",
    "single_payment_plan_desc": "{{paymentPerInterval}} دولارًا أمريكيًا تمت الفوترة مرة واحدة",
    "annual_plan_desc": "تم دفع {{paymentPerInterval}} دولار أمريكي مرتين",
    "monthly_plan_desc": "{{paymentPerInterval}} دولار أمريكي شهريًا",
    "currently_enrolled_in_x_billed_at_x_once": "",
    "paid_x_of_x": "لقد دفعت {{netAmountPaid}} دولارًا أمريكيًا من {{netRequiredPayment}} دولار أمريكي.",
    "narrative_partial_scholarship": "لقد حصلت على {num, plural, =1{ منحة دراسية } other{ المنح الدراسية }} ، مما يقلل من إجمالي الرسوم الدراسية. تهانينا!",
    "graphic_partial_scholarship_subtitle": "إجمالي {num, plural, =1{ منحة دراسية } other{ المنح الدراسية }} التي تم منحها",
    "continue_to_register": "تابع التسجيل",
    "graphic_full_scholarship_subtitle": "مجموع المنحة الممنوحة",
    "registered_payment_smallprint_with_fee_once": "سيتم تحصيل مبلغ {{paymentPerInterval}} دولارًا أمريكيًا مرة واحدة أثناء الدورة الدراسية. قد يتم تطبيق رسوم إضافية على معاملات البطاقة.",
    "prefer_later_start_date": "سجل واتصل <a href=\"mailto:admissions@quantic.edu\">بـmissions@quantic.edu</a> إذا كنت تفضل تاريخ البدء {{upcomingClassStartDate}}.",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;;text-align:right;direction:rtl\">سجل وأرسل لنا بريدًا إلكترونيًا إذا كنت تفضل ذلك<br /> {{upcomingClassStartDate}} تاريخ البدء.</div>",
    "base_tuition_no_program_title": "الرسوم الدراسية الأساسية",
    "back": "خلف",
    "bank_details_intro_1_pay_only": "قم بتزويد خدمة التحويل البنكي أو البنكي بالتفاصيل التالية:",
    "buy_now_pay_later": "ادفع لاحقا",
    "upfront_and_save": "ادفع مقدمًا <span class=\"green-text\">ووفّر مبلغًا إضافيًا قدره $ {{netDiscountAmount}}<span/>",
    "twice_and_save": "ادفع مرتين <span class=\"green-text\">ووفر مبلغًا إضافيًا قدره $ {{netDiscountAmount}}<span/>",
    "pay_monthly": "ادفع شهريًا لراحتك",
    "tuition_plan_detail_b_once": "تخفيض إجمالي الرسوم الدراسية من $ {{baseTuition}} إلى $ {{paymentPerInterval}} USD<br /> بما في ذلك المنح الدراسية وخصم الخطة",
    "tuition_plan_detail_b_annual": "يتم إصدار فاتورة بقيمة $ {{paymentPerInterval}} بالدولار الأمريكي مرتين، بفارق عام واحد",
    "tuition_plan_detail_b_monthly": "$ {{paymentPerInterval}} دولار أمريكي شهريًا لمدة {{numIntervals}} شهرًا",
    "student_preferred_plan": "الخطة المفضلة للطالب",
    "registered_payment_smallprint_recurring": "تمت جدولة دفعتك التالية بقيمة <strong>$ {{paymentPerInterval}}</strong> في <strong>{{nextPaymentDate}}</strong>.",
    "registered_payment_smallprint_with_fee_recurring": "تمت جدولة دفعتك التالية البالغة <strong>$ {{paymentPerInterval}}</strong> في <strong>{{nextPaymentDate}}</strong>. قد يتم تطبيق رسوم إضافية على معاملات البطاقة.",
    "notify_nominator": "دع {{nominatorName}} يعرف الأخبار السارة!",
    "smallprint": "نحن نتفهم أنه في حالات نادرة لا يتمكن الطلاب من الحضور. في هذه الحالة يمكنك الحفاظ على قبولك من خلال التسجيل الآن <a ng-click=\"openDeferralModal()\">والتأجيل إلى فصل دراسي مستقبلي</a> .",
    "smallprint_no_reapplication_date": "نحن نتفهم أنه في حالات نادرة لا يتمكن الطلاب من الحضور. في هذه الحالة يمكنك الحفاظ على قبولك من خلال التسجيل الآن <a ng-click=\"openDeferralModal()\">والتأجيل إلى فصل دراسي مستقبلي</a> .",
    "smallprint_scholarship": "نحن نتفهم أنه في حالات نادرة لا يتمكن الطلاب من الحضور. في هذه الحالة يمكنك الحفاظ على قبولك ومنحتك الدراسية من خلال التسجيل الآن <a ng-click=\"openDeferralModal()\">والتأجيل إلى فصل دراسي مستقبلي</a> .",
    "smallprint_scholarship_no_reapplication_date": "نحن نتفهم أنه في حالات نادرة لا يتمكن الطلاب من الحضور. في هذه الحالة يمكنك الحفاظ على قبولك ومنحتك الدراسية من خلال التسجيل الآن <a ng-click=\"openDeferralModal()\">والتأجيل إلى فصل دراسي مستقبلي</a> .",
    "tuition_plan_detail_annual": "توفير $ {{netDiscountAmount}} على دفعتين، بفارق سنة واحدة.<br /> (خيار أكثر مرونة)",
    "make_single_payment_partial_scholarship": "قم بإجراء دفعة واحدة مقدمًا ووفر {{additionalSavings}} $ من إجمالي الرسوم الدراسية.",
    "make_two_payments_partial_scholarship": "ادفع نصف المبلغ الآن والنصف الآخر بعد عام واحد ووفر {{additionalSavings}} $ من إجمالي الرسوم الدراسية. يمكن للدفعات خلال عامين تقويميين أن تساعد الطلاب على زيادة مزايا الرسوم الدراسية من شركتهم إلى أقصى حد.",
    "registration_deadline_no_start_date": "الموعد النهائي للتسجيل هو {{registrationDeadline}} .",
    "registration_deadline_mobile_no_start_date": "الموعد النهائي للتسجيل: {{registrationDeadline}} .",
    "choose_start_date": "اختر تاريخ بدء الفصل الدراسي الخاص بك:",
    "choose_either_cohort": "كإحدى مزايا القبول المبكر،<br /> يمكنك اختيار أي مجموعة.",
    "funding_options": "اتصل بـ <a href=\"mailto:{{fundingEmail}}\" class=\"text-primary\">{{fundingEmail} }</a> للحصول على المزيد من خيارات التمويل."
}
};window.locales.en = window.locales.en || {};window.locales.en.settings = window.locales.en.settings || {};window.locales.en.settings.tuition_and_registration = window.locales.en.settings.tuition_and_registration || {};window.locales.en.settings.tuition_and_registration = {...window.locales.en.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "Allow classmates to see my profile",
    "notify_nominator": "Let {{nominatorName}} know the good news!",
    "header_choose_your_tuition_plan": "Choose Your Tuition Plan",
    "header_register": "Register for the {{programTitle}}",
    "narrative_all_paid": "You have paid your tuition in full. Thank you!",
    "new_browser_register": "To register, you will need to use your system web browser.",
    "new_browser_payment_details": "To modify your payment details, you will need to use your system web browser.",
    "confirm_message": "To confirm your place in the class, please register by <strong>{{registrationDeadline}}</strong>.",
    "confirm_message_past_deadline": "To confirm your place in the class, please register now.",

                     
    "narrative_no_scholarship": "Welcome to the program! Please review the payment options and register below.",
    "graphic_no_scholarship_title": "<b>Congratulations</b>",
    "graphic_no_scholarship_subtitle": "Register to enroll",

                             
    "narrative_partial_scholarship": "You have been awarded the following {num, plural, =1{scholarship} other{scholarships}}, reducing your total tuition. Congratulations!",
    "graphic_partial_scholarship_title": "<strong>${{totalScholarship}}</strong> USD",
    "graphic_partial_scholarship_subtitle": "Total {num, plural, =1{Scholarship} other{Scholarships}} Awarded",

                       
    "narrative_full_scholarship": "You have been awarded a full scholarship to the program. Congratulations on this achievement!",
    "graphic_full_scholarship_title": "<strong>${{totalScholarship}}</strong> USD",
    "graphic_full_scholarship_subtitle": "Total Scholarship Awarded",

    "register": "Register",
    "review_and_register": "Review &amp; Register",
    "contact_bursar": "Contact Bursar",
    "email_our_bursar": "Email our Bursar for instructions on how to register and pay by wire transfer.",
    "launch_browser": "Launch Web Browser",
    "registered_contact_support": "Please contact <a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a> for account information and payment receipts.",
    "registered_manage_billing": "Manage Billing",
    "registered_manage_billing_payment_trouble": "Fix Payment Details",
    "registered_manage_billing_view_payment_history": "View Payment History",
    "registered_processing_payment": "We are processing your payment.",
    "registered_payment_smallprint_recurring": "Your next payment of <strong>${{paymentPerInterval}}</strong> is scheduled for <strong>{{nextPaymentDate}}</strong>.",
    "registered_payment_smallprint_once": "You will be charged <strong>${{paymentPerInterval}}</strong> once during the course of study.",
    "registered_payment_smallprint_with_fee_recurring": "Your next payment of <strong>${{paymentPerInterval}}</strong> is scheduled for <strong>{{nextPaymentDate}}</strong>. Additional fee may apply to card transactions.",
    "registered_payment_smallprint_with_fee_once": "You will be charged <strong>${{paymentPerInterval}}</strong> once during the course of study. Additional fee may apply to card transactions.",
    "stripe_name": "{{brandName}} {{fullTitle}}",
    "stripe_description_monthly_tuition": "Monthly Tuition Plan",
    "stripe_description_annual_tuition": "Annual Tuition Plan",
    "stripe_description_once_tuition": "Single Payment",
    "smallprint": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "smallprint_no_reapplication_date": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "smallprint_scholarship": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission and scholarship by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "smallprint_scholarship_no_reapplication_date": "We understand in rare circumstances students are unable to attend. In this case you can preserve your admission and scholarship by registering now and <a ng-click=\"openDeferralModal()\">deferring to a future class</a>.",
    "stripe_panel_label_pay_tuition": "Pay Tuition – ",
    "processing": "Processing ...",
    "scholarship_award": "Scholarship Award",
    "scholarship_award_with_label": "{{scholarshipLabel}} Scholarship Award",
    "trouble_processing_payment": "We're having trouble processing your payment.",
    "please_re_enter_payment_details": "Please re-enter the payment details to resolve the issue.",
    "action_required": "Action Required",
    "confirm_payment": "We've sent you an email to confirm your recent ${{paymentPerInterval}} USD payment.",
    "additional_steps_required": "To protect you from fraud, your bank requires an additional step to ensure online transactions are authorized. Please confirm your payment to Quantic to complete your registration.",
    "funding_options": "Contact <a href=\"mailto:{{fundingEmail}}\" class=\"text-primary\">{{fundingEmail}}</a> for more financing options.",

    "currently_enrolled_in_x_billed_at_x_once": "",
    "currently_enrolled_in_x_billed_at_x_monthly_during": "You are currently enrolled in the Monthly Tuition Plan billed at <b>${{paymentPerInterval}} USD per month</b>.",
    "currently_enrolled_in_x_billed_at_x_monthly": "You are currently enrolled in the Monthly Tuition Plan billed at <b>${{paymentPerInterval}} USD per month</b>.",
    "currently_enrolled_in_x_billed_at_x_annual_during": "You are currently enrolled in the Annual Tuition Plan billed <b>twice at ${{paymentPerInterval}} USD</b> during the course of study.",
    "currently_enrolled_in_x_billed_at_x_annual": "You are currently enrolled in the Annual Tuition Plan billed <b>twice at ${{paymentPerInterval}} USD</b>.",
    "paid_x_of_x": "You have paid ${{netAmountPaid}} out of ${{netRequiredPayment}} USD.",

                
    "confirm_message_before_deadline": "Registration opens on<br class=\"visible-xs\" /> <strong>{{registrationOpen}}</strong>.",
    "registration_deadline": "The deadline to register is {{registrationDeadline}}. Class starts on {{classStartDate}}.",
    "registration_deadline_no_start_date": "The deadline to register is {{registrationDeadline}}.",
    "registration_deadline_mobile": "Registration Deadline: {{registrationDeadline}}.<div style=\"padding-top: 7px;\">Class starts on {{classStartDate}}.</div>",
    "registration_deadline_mobile_no_start_date": "Registration Deadline: {{registrationDeadline}}.",
    "prefer_later_start_date": "Register and contact <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> if you prefer the {{upcomingClassStartDate}} start date.",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;\">Register and email us if you prefer the<br /> {{upcomingClassStartDate}} start date.</div>",

                           
    "choose_start_date": "Choose your class start date:",
    "choose_either_cohort": "As an Early Admission benefit,<br /> you may choose either cohort.",

                        
    "choose_your_plan": "Choose your tuition plan:",
    "available_plans": "Available Tuition Plans:",
    "single_payment_plan_desc": "${{paymentPerInterval}} USD billed once",
    "annual_plan_desc": "${{paymentPerInterval}} USD billed twice",
    "monthly_plan_desc": "${{paymentPerInterval}} USD per month",

                              
    "plan_description_once": "Includes ${{additionalSavings}} additional savings.",
    "plan_description_annual": "Includes ${{additionalSavings}} additional savings.",

                                 
      
    "plan_description_monthly": "Most convenient billing option.",

                               
    "plan_description_once_partial_scholarship": "Reduced from ${{paymentPerIntervalForFullTuition}}. Includes ${{additionalSavings}} additional savings.",
    "plan_description_annual_partial_scholarship": "Reduced from ${{paymentPerIntervalForFullTuition}}. Includes ${{additionalSavings}} additional savings.",

                              
      
    "plan_description_monthly_partial_scholarship": "Reduced from ${{paymentPerIntervalForFullTuition}} per month.",

                                
    "explain_the_options": "explain the options",
    "choose_tuition": "Choose the tuition option that is right for you",
    "payment_options": "Payment Options",
    "option_one": "Option 1",
    "option_two": "Option 2",
    "option_three": "Option 3",
    "monthly_option": "Monthly Option",
    "annual_option": "2-Pay Option",
    "upfront_option": "1-Pay Option",
    "make_single_payment": "Make a single upfront payment and save ${{additionalSavings}} off your total tuition.",
    "make_single_payment_partial_scholarship": "Make a single upfront payment and save ${{additionalSavings}} off your total tuition.",
    "make_two_payments": "Pay half now and half one year later and save ${{additionalSavings}} off your total tuition. Payments in 2 calendar years can help students maximize tuition benefits from their company.",
    "make_two_payments_partial_scholarship": "Pay half now and half one year later and save ${{additionalSavings}} off your total tuition. Payments in 2 calendar years can help students maximize tuition benefits from their company.",
                                                                                                                          
                                                     
      
    "make_monthly_payments": "Make {{numIntervals}} convenient monthly payments over the duration of the program.",

    "refund_policy": "Our generous <a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">refund policy</a> includes a full refund of any payments prior to the start of classes.",

                            
    "which_section_is_right": "Which Section is Right for You?",
    "two_different_cohort_sections": "You live in a region where we offer two different cohort sections.",
    "global_section_explanation": "Our <b>Global Section</b> has students from over 30 countries, with more international perspectives in group projects and exercises. Over half the class lives in North America and Western Europe.",
    "regional_section_explanation": "Our <b>{{regionalSectionName}}</b> has only students from this region, enabling greater local networking while delivering lower tuition cost through corporate sponsorship and increased scholarship awards.",
    "both_sections_same_explanation": "Both sections use the same curriculum materials, projects, and case studies, while earning the same Quantic degree. Both have access to our optional in-person events as well as the full global alumni network.",

                     
    "deferring_admission": "Deferring Admission",
    "choose_different_start_date": "If you are unable to join the current cohort that begins on {{startDate}}, you can preserve your positive admission by registering now and choosing one of the following cohort start dates:",
    "choose_different_start_date_scholarship": "If you are unable to join the current cohort that begins on {{startDate}}, you can preserve your positive admission and scholarship award by registering now and choosing one of the following cohort start dates:",
    "contact_us_with_preferred_start_date": "Once you have registered, simply reach out to us with your preferred date at {{admissionsEmail}}.",
    "reapply_at_different_time": "If you are unable to register, you may re-apply in {{reapplicationMonthYear}}. We are unable to guarantee future admission in this case.",
    "reapply_at_different_time_scholarship": "If you are unable to register, you may re-apply in {{reapplicationMonthYear}}. We are unable to guarantee future admission or the same scholarship in this case.",
    "reapply_at_different_time_no_date": "If you are unable to register, you may re-apply at a later time. We are unable to guarantee future admission in this case.",
    "reapply_at_different_time_scholarship_no_date": "If you are unable to register, you may re-apply at a later time. We are unable to guarantee future admission or the same scholarship in this case.",

                         
    "wiring_funds": "Wiring Funds",
    "bank_details_intro": "Provide your bank or wire transfer service the following details if choosing the 1-pay or 2-pay options. We do not accept wires for the monthly billing option.",
    "bank_details_intro_1_pay_only": "Provide your bank or wire transfer service with the following details:",
    "our_bank_heading": "Our Bank",
    "our_account_name_heading": "Our Account Name",
    "our_account_number_heading": "Our Account Number",
    "for_us_wires_heading": "For US Wires",
    "for_us_wires_content": "Routing &amp; Transit number: {{transitNumber}}",
    "for_international_wires_heading": "For International Wires",
    "quantic_mailing_address_heading": "{{institutionName}} Mailing Address",
    "identifier": "Identifier: Full name of student and sender's name and address",
    "contact_billing_with_questions": "After initiating payment, contact {{billingEmail}} to confirm your registration.",

                                             
    "choose_your_section": "Choose your cohort section to review scholarship and tuition options.",
    "whats_the_difference": "What's the difference?",
    "global_section": "Global Section",
    "regional_section": "S / SE Asia Section",
    "global_section_subtext": "Study and collaborate with students from over 30 countries.",
    "regional_section_subtext": "Study and collaborate with regional classmates only. Greater scholarship reduces tuition.",
    "regional_section_subtext_full_scholarship": "Study and collaborate with regional classmates only.",

    "tuition_details": "Tuition Details",
    "tuition_and_scholarship_details": "Tuition & Scholarship Details",

                             
    "base_tuition_no_scholarship": "{{programTitle}} Tuition",
    "base_tuition": "Base {{programTitle}} Tuition",
    "base_tuition_no_program_title": "Base Tuition",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} USD</span>",
    "discount_of_ledger_item": "– $ <span class=\"value\">{{value}} USD</span>",
    "total_amount": "(for {{numIntervals}} months = ${{totalAmount}} USD)",
    "total_tuition": "Total Tuition",
    "full_scholarship": "(Full Scholarship)",

                            
    "select_payment_method": "Select Payment Method",
    "continue_to_register": "Continue to Register",
    "back_to_plan_selection": "Back to Plan Selection",
    "back": "Back",
    "button_view_wire_transfer_instructions": "View Instructions",
    "us_bank_account": "US Bank Account",
    "card": "Card",
    "buy_now_pay_later": "Pay Later",
    "wire_transfer": "Wire Transfer",
    "transaction_fee": "{{creditCardPaymentFeeRate}}% transaction fee",
    "smallprint_transaction_fee": "Fee not applicable for debit cards or prepaid cards. Payments using debit/prepaid cards will have the fee refunded immediately after checkout.",
    "selected_tuition_plan_once": "Selected Tuition Plan: ${{paymentAmountPerInterval}} USD charged once",
    "selected_tuition_plan_annual": "Selected Tuition Plan: ${{paymentAmountPerInterval}} USD charged twice",
    "selected_tuition_plan_monthly": "Selected Tuition Plan: ${{paymentAmountPerInterval}} USD per month",
    "tuition_plan_detail_once": "${{netDiscountAmount}} savings for a single upfront payment.<br />(lowest tuition option)",
    "tuition_plan_detail_annual": "${{netDiscountAmount}} savings for 2 payments, one year apart.<br />(more flexible option)",
    "tuition_plan_detail_monthly": "{{numIntervals}} payments over the course of the program.<br />(most convenient option)",

                           
    "upfront_and_save": "Pay upfront and <span class=\"green-text\">save an additional ${{netDiscountAmount}}<span/>",
    "twice_and_save": "Pay twice and <span class=\"green-text\">save an additional ${{netDiscountAmount}}<span/>",
    "pay_monthly": "Pay monthly for your convenience",
    "tuition_plan_detail_b_once": "Reduces total tuition from ${{baseTuition}} to ${{paymentPerInterval}} USD <br />including scholarships and plan discount",
    "tuition_plan_detail_b_annual": "${{paymentPerInterval}} USD billed twice, a year apart",
    "tuition_plan_detail_b_monthly": "${{paymentPerInterval}} USD per month for {{numIntervals}} months",
    "student_preferred_plan": "Student preferred plan"
}
};window.locales.es = window.locales.es || {};window.locales.es.settings = window.locales.es.settings || {};window.locales.es.settings.tuition_and_registration = window.locales.es.settings.tuition_and_registration || {};window.locales.es.settings.tuition_and_registration = {...window.locales.es.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "Permitir que los compañeros de clase vean mi perfil",
    "header_choose_your_tuition_plan": "Elija su plan de matrícula",
    "new_browser_register": "Para registrarse, deberá utilizar el navegador web de su sistema.",
    "new_browser_payment_details": "Para modificar sus datos de pago, deberá utilizar el navegador web de su sistema.",
    "confirm_message": "Para confirmar su lugar en la clase, regístrese antes del <strong>{{registrationDeadline}}</strong>.",
    "confirm_message_past_deadline": "Para confirmar su lugar en la clase, regístrese ahora.",
    "register": "Registrarse",
    "launch_browser": "Iniciar navegador web",
    "stripe_description_monthly_tuition": "Plan de matrícula mensual",
    "stripe_description_annual_tuition": "Plan anual de matrícula",
    "stripe_description_once_tuition": "Pago unico",
    "stripe_panel_label_pay_tuition": "Pagar Ahora -",           
    "processing": "Procesando ...",
    "scholarship_award": "Premio de beca",
    "please_re_enter_payment_details": "Vuelva a ingresar los detalles del pago para resolver el problema.",
    "currently_enrolled_in_x_billed_at_x_monthly_during": "Actualmente está inscrito en el plan de matrícula mensual facturado a <b>${{paymentPerInterval}} USD por mes</b>.",
    "currently_enrolled_in_x_billed_at_x_monthly": "Actualmente está inscrito en el plan de matrícula mensual facturado a <b>${{paymentPerInterval}} USD por mes</b>.",
    "currently_enrolled_in_x_billed_at_x_annual_during": "Actualmente está inscrito en el Plan de matrícula anual facturado <b>dos veces a ${{paymentPerInterval}} USD</b> durante el curso de estudio.",
    "currently_enrolled_in_x_billed_at_x_annual": "Actualmente está inscrito en el plan de matrícula anual facturado <b>dos veces a ${{paymentPerInterval}} USD</b>.",
    "confirm_message_before_deadline": "El registro se abre el <strong>{{registrationOpen}}</strong>.",
    "choose_your_plan": "Elige tu plan de matrícula",
    "explain_the_options": "explica las opciones",
    "choose_tuition": "Elija la opción de matrícula adecuada para usted",
    "option_one": "Opción 1",
    "option_two": "opcion 2",
    "option_three": "Opción 3",
    "header_register": "Regístrese para el {{programTitle}}",
    "stripe_name": "{{brandName}} {{fullTitle}}",
    "scholarship_award_with_label": "Premio de {{scholarshipLabel}}",
    "registered_contact_support": "Comuníquese con <a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a> para obtener información de la cuenta y recibos de pago.",
    "registered_manage_billing": "Administrar facturación",
    "registered_manage_billing_payment_trouble": "Corregir detalles de pago",
    "registered_manage_billing_view_payment_history": "Ver historial de pagos",
    "contact_bursar": "Contacta con el tesorero",
    "email_our_bursar": "Envíe un correo electrónico a nuestro tesorero para obtener instrucciones sobre cómo registrarse y pagar mediante transferencia bancaria.",
    "available_plans": "Planes de matrícula disponibles:",
    "which_section_is_right": "¿Qué sección es la adecuada para usted?",
    "two_different_cohort_sections": "Vives en una región donde ofrecemos dos secciones de cohortes diferentes.",
    "global_section_explanation": "Nuestra <b>Sección Global</b> cuenta con estudiantes de más de 30 países, con perspectivas más internacionales en proyectos y ejercicios grupales. Más de la mitad de la clase vive en América del Norte y Europa Occidental.",
    "both_sections_same_explanation": "Ambas secciones utilizan los mismos materiales curriculares, proyectos y estudios de casos, mientras obtienen el mismo título de Quantic. Ambos tienen acceso a nuestros eventos en persona opcionales, así como a la red global de ex alumnos.",
    "choose_your_section": "Elija su sección de cohorte para revisar las opciones de becas y matrícula.",
    "whats_the_difference": "¿Cual es la diferencia?",
    "global_section": "Sección global",
    "regional_section": "Sección S / SE Asia",
    "global_section_subtext": "Estudia y colabora con estudiantes de más de 30 países.",
    "regional_section_subtext": "Estudie y colabore solo con compañeros de clase regionales. Una mayor beca reduce la matrícula.",
    "regional_section_subtext_full_scholarship": "Estudie y colabore solo con compañeros de clase regionales.",
    "registration_deadline": "La fecha límite para registrarse es<br class=\"visible-xs\" /> {{registrationDeadline}}.<br class=\"visible-xs\" /><div class=\"visible-xs\" style=\"padding-top: 10px;\"></div> La clase comienza el {{classStartDate}}.",
    "regional_section_explanation": "Nuestro <b>{{regionalSectionName}}</b> solo tiene estudiantes de esta región, lo que permite una mayor red local al tiempo que ofrece un costo de matrícula más bajo a través del patrocinio corporativo y el aumento de las becas.",
    "action_required": "Acción requerida",
    "confirm_payment": "Le enviamos un correo electrónico para confirmar su {{paymentPerInterval}} USD.",
    "additional_steps_required": "Para protegerlo contra el fraude, su banco requiere un paso adicional para garantizar que las transacciones en línea estén autorizadas. Confirme su pago a Quantic para completar su registro.",
    "plan_description_monthly_partial_scholarship": "Reducido de $ {{paymentPerIntervalForFullTuition}} por mes.",
    "payment_options": "Opciones de pago",
    "make_monthly_payments": "Realice {{numIntervals}} convenientes pagos mensuales durante la duración del programa.",
    "refund_policy": "Nuestra generosa <a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">política de reembolso</a> incluye un reembolso completo de cualquier pago antes del comienzo de las clases.",
    "deferring_admission": "Admisión diferida",
    "wiring_funds": "Fondos de cableado",
    "our_bank_heading": "Nuestro banco",
    "our_account_name_heading": "Nuestro nombre de cuenta",
    "our_account_number_heading": "Nuestro número de cuenta",
    "for_us_wires_heading": "Para cables de EE. UU.",
    "for_international_wires_heading": "Para Cables Internacionales",
    "identifier": "Identificador: Nombre completo del estudiante y nombre y dirección del remitente",
    "monthly_option": "Opción Mensual",
    "annual_option": "Opción de 2 pagos",
    "upfront_option": "Opción de 1 pago",
    "review_and_register": "Revisar y registrarse",
    "contact_us_with_preferred_start_date": "Una vez que se haya registrado, simplemente comuníquese con nosotros con su fecha preferida en {{admissionsEmail}}.",
    "contact_billing_with_questions": "Después de iniciar el pago, comuníquese con {{billingEmail}} para confirmar su registro.",
    "bank_details_intro": "Proporcione a su banco o servicio de transferencia bancaria los siguientes detalles si elige las opciones de 1 pago o 2 pagos. No aceptamos transferencias para la opción de facturación mensual.",
    "choose_different_start_date": "Si no puede unirse a la cohorte actual que comienza el {{startDate}} , puede conservar su admisión positiva registrándose ahora y eligiendo una de las siguientes fechas de inicio de la cohorte:",
    "choose_different_start_date_scholarship": "Si no puede unirse a la cohorte actual que comienza el {{startDate}} , puede conservar su admisión positiva y la concesión de la beca registrándose ahora y eligiendo una de las siguientes fechas de inicio de la cohorte:",
    "reapply_at_different_time": "Si no puede registrarse, puede volver a presentar la solicitud en {{reapplicationMonthYear}}. No podemos garantizar la admisión futura en este caso.",
    "reapply_at_different_time_scholarship": "Si no puede registrarse, puede volver a presentar la solicitud en {{reapplicationMonthYear}}. No podemos garantizar la admisión futura o la misma beca en este caso.",
    "reapply_at_different_time_no_date": "Si no puede registrarse, puede volver a aplicar en otro momento. No podemos garantizar la admisión futura en este caso.",
    "reapply_at_different_time_scholarship_no_date": "Si no puede registrarse, puede volver a aplicar en otro momento. No podemos garantizar la admisión futura o la misma beca en este caso.",
    "for_us_wires_content": "Número de ruta y tránsito: {{transitNumber}}",
    "quantic_mailing_address_heading": "{{institutionName}} Dirección Postal",
    "registered_processing_payment": "Estamos procesando su pago.",
    "trouble_processing_payment": "Estamos teniendo problemas para procesar su pago.",
    "registered_payment_smallprint_once": "Se le cobrará ${{paymentPerInterval}} una vez durante el curso del estudio.",
    "plan_description_once": "Incluye $ {{additionalSavings}} ahorros adicionales.",
    "plan_description_annual": "Incluye $ {{additionalSavings}} ahorros adicionales.",
    "plan_description_monthly": "La opción de facturación más conveniente.",
    "plan_description_once_partial_scholarship": "Reducido de $ {{paymentPerIntervalForFullTuition}}. Incluye $ {{additionalSavings}} ahorros adicionales.",
    "plan_description_annual_partial_scholarship": "Reducido de $ {{paymentPerIntervalForFullTuition}}. Incluye $ {{additionalSavings}} ahorros adicionales.",
    "make_single_payment": "Realice un pago único por adelantado y ahorre $ {{additionalSavings}} de su matrícula total.",
    "make_two_payments": "Pague la mitad ahora y la mitad un año después y ahorre $ {{additionalSavings}} de su matrícula total. Los pagos en 2 años calendario pueden ayudar a los estudiantes a maximizar los beneficios de matrícula de su empresa.",
    "narrative_all_paid": "Has pagado tu matrícula en su totalidad. ¡Gracias!",
    "narrative_no_scholarship": "¡Bienvenido al programa! Revise las opciones de pago y regístrese a continuación.",
    "narrative_full_scholarship": "Se le ha otorgado una beca completa para el programa. ¡Felicitaciones por este logro!",
    "graphic_full_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "registration_deadline_mobile": "Fecha límite de registro: {{registrationDeadline}}.<div style=\"padding-top: 7px;\"> La clase comienza el {{classStartDate}}.</div>",
    "tuition_details": "Detalles de la matrícula",
    "tuition_and_scholarship_details": "Detalles de matrícula y becas",
    "graphic_no_scholarship_title": "<b>Felicidades</b>",
    "graphic_no_scholarship_subtitle": "Regístrese para inscribirse",
    "graphic_partial_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "base_tuition_no_scholarship": "{{programTitle}} Matrícula",
    "base_tuition": "Matrícula básica {{programTitle}}",
    "total_amount": "(para {{numIntervals}} meses = $ {{totalAmount}} USD)",
    "total_tuition": "Matrícula total",
    "full_scholarship": "(Beca completa)",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} USD</span>",
    "discount_of_ledger_item": "– $ <span class=\"value\">{{value}} USD</span>",
    "select_payment_method": "Seleccionar método de pago",
    "back_to_plan_selection": "Volver a Selección de planes",
    "button_view_wire_transfer_instructions": "Ver instrucciones",
    "us_bank_account": "Cuenta bancaria de EE. UU.",
    "card": "Tarjeta",
    "wire_transfer": "Transferencia bancaria",
    "transaction_fee": "{{creditCardPaymentFeeRate}}% tarifa de transacción",
    "smallprint_transaction_fee": "Tarifa no aplicable para tarjetas de débito o tarjetas prepagas. Los pagos con tarjetas de débito/prepago tendrán la tarifa reembolsada inmediatamente después del pago.",
    "selected_tuition_plan_once": "Plan de matrícula seleccionado: ${{paymentAmountPerInterval}} USD cobrado una vez",
    "selected_tuition_plan_annual": "Plan de matrícula seleccionado: ${{paymentAmountPerInterval}} USD cobrado dos veces",
    "selected_tuition_plan_monthly": "Plan de matrícula seleccionado: ${{paymentAmountPerInterval}} USD por mes",
    "tuition_plan_detail_once": "${{netDiscountAmount}} de ahorro por un solo pago por adelantado.<br /> (opción de matrícula más baja)",
    "tuition_plan_detail_monthly": "{{numIntervals}} pagos durante el transcurso del programa.<br /> (opción más conveniente)",
    "single_payment_plan_desc": "${{paymentPerInterval}} USD facturado una vez",
    "annual_plan_desc": "${{paymentPerInterval}} USD facturado dos veces",
    "monthly_plan_desc": "${{paymentPerInterval}} USD por mes",
    "currently_enrolled_in_x_billed_at_x_once": "",
    "paid_x_of_x": "Ha pagado $ {{netAmountPaid}} de $ {{netRequiredPayment}} USD.",
    "narrative_partial_scholarship": "Se le ha otorgado  {num, plural, =1{ la siguiente beca } other{ las siguientes becas }}, lo que reduce su matrícula total. ¡Felicidades!",
    "graphic_partial_scholarship_subtitle": "{num, plural, =1{ Beca Total Otorgada } other{ Total de Becas Otorgadas }}",
    "continue_to_register": "Continuar para registrarse",
    "graphic_full_scholarship_subtitle": "Beca total otorgada",
    "registered_payment_smallprint_with_fee_once": "Se le cobrará $ {{paymentPerInterval}} una vez durante el curso del estudio. Se pueden aplicar cargos adicionales a las transacciones con tarjeta.",
    "prefer_later_start_date": "Regístrese y comuníquese con <a href=\"mailto:admissions@quantic.edu\">admisiones@quantic.edu</a> si prefiere la fecha de inicio {{upcomingClassStartDate}}.",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;\">Regístrate y envíanos un correo electrónico si prefieres el<br /> {{upcomingClassStartDate}} fecha de inicio.</div>",
    "base_tuition_no_program_title": "Matrícula básica",
    "back": "Atrás",
    "bank_details_intro_1_pay_only": "Proporcione a su banco o servicio de transferencia bancaria los siguientes detalles:",
    "buy_now_pay_later": "Paga después",
    "upfront_and_save": "Pague por adelantado y <span class=\"green-text\">ahorre $ {{netDiscountAmount}} adicional<span/>",
    "twice_and_save": "Paga dos veces y <span class=\"green-text\">ahorra $ {{netDiscountAmount}} adicional<span/>",
    "pay_monthly": "Paga mensualmente para tu comodidad",
    "tuition_plan_detail_b_once": "Reduce la matrícula total de $ {{baseTuition}} a $ {{paymentPerInterval}} USD<br /> incluyendo becas y descuento del plan.",
    "tuition_plan_detail_b_annual": "$ {{paymentPerInterval}} USD facturados dos veces, con un año de diferencia",
    "tuition_plan_detail_b_monthly": "$ {{paymentPerInterval}} USD por mes durante {{numIntervals}} meses",
    "student_preferred_plan": "Plan preferido por estudiantes",
    "registered_payment_smallprint_recurring": "Su próximo pago de <strong>$ {{paymentPerInterval}}</strong> está programado para <strong>{{nextPaymentDate}}</strong>.",
    "registered_payment_smallprint_with_fee_recurring": "Su próximo pago de <strong>$ {{paymentPerInterval}}</strong> está programado para <strong>{{nextPaymentDate}}</strong>. Es posible que se apliquen cargos adicionales a las transacciones con tarjeta.",
    "notify_nominator": "¡Cuéntale a {{nominatorName}} las buenas noticias!",
    "smallprint": "Entendemos que en circunstancias excepcionales los estudiantes no pueden asistir. En este caso, puede conservar su admisión registrándose ahora y <a ng-click=\"openDeferralModal()\">postergándola para una clase futura</a> .",
    "smallprint_no_reapplication_date": "Entendemos que en circunstancias excepcionales los estudiantes no pueden asistir. En este caso, puede conservar su admisión registrándose ahora y <a ng-click=\"openDeferralModal()\">postergándola para una clase futura</a> .",
    "smallprint_scholarship": "Entendemos que en circunstancias excepcionales los estudiantes no pueden asistir. En este caso, puede conservar su admisión y beca registrándose ahora y <a ng-click=\"openDeferralModal()\">postergándola para una clase futura</a> .",
    "smallprint_scholarship_no_reapplication_date": "Entendemos que en circunstancias excepcionales los estudiantes no pueden asistir. En este caso, puede conservar su admisión y beca registrándose ahora y <a ng-click=\"openDeferralModal()\">postergándola para una clase futura</a> .",
    "tuition_plan_detail_annual": "$ {{netDiscountAmount}} de ahorro por 2 pagos, con un año de diferencia.<br /> (opción más flexible)",
    "make_single_payment_partial_scholarship": "Realice un pago único por adelantado y ahorre $ {{additionalSavings}} de su matrícula total.",
    "make_two_payments_partial_scholarship": "Paga la mitad ahora y la otra mitad un año después y ahorra $ {{additionalSavings}} de tu matrícula total. Los pagos en 2 años calendario pueden ayudar a los estudiantes a maximizar los beneficios de matrícula de su empresa.",
    "registration_deadline_no_start_date": "La fecha límite para registrarse es {{registrationDeadline}} .",
    "registration_deadline_mobile_no_start_date": "Fecha límite de inscripción: {{registrationDeadline}} .",
    "choose_start_date": "Elige la fecha de inicio de tu clase:",
    "choose_either_cohort": "Como beneficio de admisión anticipada,<br /> Puedes elegir cualquiera de las cohortes.",
    "funding_options": "Comuníquese con <a href=\"mailto:{{fundingEmail}}\" class=\"text-primary\">{{fundingEmail} }</a> para obtener más opciones de financiamiento."
}
};window.locales.it = window.locales.it || {};window.locales.it.settings = window.locales.it.settings || {};window.locales.it.settings.tuition_and_registration = window.locales.it.settings.tuition_and_registration || {};window.locales.it.settings.tuition_and_registration = {...window.locales.it.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "Consenti ai compagni di classe di vedere il mio profilo",
    "header_choose_your_tuition_plan": "Scegli il tuo piano di insegnamento",
    "narrative_all_paid": "Hai pagato per intero la tua retta. Grazie!",
    "new_browser_register": "Per registrarti, dovrai utilizzare il browser web del tuo sistema.",
    "new_browser_payment_details": "Per modificare i dettagli di pagamento, dovrai utilizzare il browser web del tuo sistema.",
    "confirm_message": "Per confermare il tuo posto nella classe, registrati entro <strong>{{registrationDeadline}}</strong>.",
    "confirm_message_past_deadline": "Per confermare il tuo posto in classe, registrati ora.",
    "register": "Registrati",
    "launch_browser": "Avvia il browser web",
    "stripe_description_monthly_tuition": "Piano mensile delle lezioni",
    "stripe_description_annual_tuition": "Piano annuale delle lezioni",
    "stripe_description_once_tuition": "Pagamento unico",
    "stripe_panel_label_pay_tuition": "Paga lezioni -",
    "processing": "In lavorazione ...",
    "scholarship_award": "Premio borsa di studio",
    "please_re_enter_payment_details": "Inserisci nuovamente i dettagli del pagamento per risolvere il problema.",
    "currently_enrolled_in_x_billed_at_x_monthly_during": "Sei attualmente iscritto al piano di lezioni mensile fatturato a <b>${{paymentPerInterval}} USD al mese</b>.",
    "currently_enrolled_in_x_billed_at_x_monthly": "Sei attualmente iscritto al piano di lezioni mensile fatturato a <b>${{paymentPerInterval}} USD al mese</b>.",
    "currently_enrolled_in_x_billed_at_x_annual_during": "Sei attualmente iscritto al piano annuale di lezioni fatturate <b>due volte a ${{paymentPerInterval}} USD</b> durante il corso di studi.",
    "currently_enrolled_in_x_billed_at_x_annual": "Sei attualmente iscritto al piano di lezioni annuale fatturato <b>due volte a ${{paymentPerInterval}} USD</b>.",
    "confirm_message_before_deadline": "La registrazione si apre il <strong>{{registrationOpen}}</strong>.",
    "choose_your_plan": "Scegli il tuo piano di insegnamento",
    "explain_the_options": "spiegare le opzioni",
    "choose_tuition": "Scegli l'opzione di insegnamento giusta per te",
    "option_one": "opzione 1",
    "option_two": "opzione 2",
    "option_three": "Opzione 3",
    "header_register": "Registrati per {{programTitle}}",
    "stripe_name": "{{brandName}} {{fullTitle}}",
    "scholarship_award_with_label": "{{scholarshipLabel}} Premio per la borsa di studio",
    "registered_contact_support": "Contatta <a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a> per informazioni sull'account e ricevute di pagamento.",
    "registered_manage_billing": "Gestisci fatturazione",
    "registered_manage_billing_payment_trouble": "Correggi i dettagli di pagamento",
    "registered_manage_billing_view_payment_history": "Visualizza la cronologia dei pagamenti",
    "contact_bursar": "Contatta l'economo",
    "email_our_bursar": "Invia un'e-mail al nostro Economo per istruzioni su come registrarsi e pagare tramite bonifico bancario.",
    "available_plans": "Piani di insegnamento disponibili:",
    "which_section_is_right": "Qual è la sezione giusta per te?",
    "two_different_cohort_sections": "Vivi in una regione in cui offriamo due diverse sezioni di coorte.",
    "global_section_explanation": "La nostra <b>sezione globale</b> ha studenti provenienti da oltre 30 paesi, con più prospettive internazionali in progetti ed esercizi di gruppo. Più della metà della classe vive in Nord America e in Europa occidentale.",
    "both_sections_same_explanation": "Entrambe le sezioni utilizzano gli stessi materiali curriculari, progetti e casi di studio, mentre conseguono lo stesso grado Quantic. Entrambi hanno accesso ai nostri eventi di persona opzionali e all'intera rete globale di ex studenti.",
    "choose_your_section": "Scegli la sezione della tua coorte per rivedere le opzioni di borsa di studio e lezioni.",
    "whats_the_difference": "Qual è la differenza?",
    "global_section": "Sezione globale",
    "regional_section": "Sezione S/SE Asia",
    "global_section_subtext": "Studia e collabora con studenti di oltre 30 paesi.",
    "regional_section_subtext": "Studia e collabora solo con i compagni di classe regionali. Una borsa di studio maggiore riduce le tasse scolastiche.",
    "regional_section_subtext_full_scholarship": "Studia e collabora solo con i compagni di classe regionali.",
    "registration_deadline": "La scadenza per iscriversi è<br class=\"visible-xs\" /> {{registrationDeadline}}.<br class=\"visible-xs\" /><div class=\"visible-xs\" style=\"padding-top: 10px;\"></div> La lezione inizia il {{classStartDate}}.",
    "regional_section_explanation": "Il nostro <b>{{regionalSectionName}}</b> ha solo studenti di questa regione, consentendo una maggiore rete locale e offrendo costi di iscrizione inferiori attraverso la sponsorizzazione aziendale e maggiori borse di studio.",
    "action_required": "Azione richiesta",
    "confirm_payment": "Ti abbiamo inviato un'email per confermare il tuo recente {{paymentPerInterval}} USD.",
    "additional_steps_required": "Per proteggerti dalle frodi, la tua banca richiede un passaggio aggiuntivo per garantire che le transazioni online siano autorizzate. Conferma il pagamento a Quantic per completare la registrazione.",
    "plan_description_monthly_partial_scholarship": "Ridotto da $ {{paymentPerIntervalForFullTuition}} al mese.",
    "payment_options": "Opzioni di pagamento",
    "make_monthly_payments": "Effettua {{numIntervals}} pagamenti mensili convenienti per tutta la durata del programma.",
    "refund_policy": "La nostra generosa <a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">politica di rimborso</a> include un rimborso completo di tutti i pagamenti prima dell'inizio delle lezioni.",
    "deferring_admission": "Ammissione differita",
    "wiring_funds": "Cablaggio di fondi",
    "our_bank_heading": "La nostra banca",
    "our_account_name_heading": "Il nostro nome account",
    "our_account_number_heading": "Il nostro numero di conto",
    "for_us_wires_heading": "Per i fili statunitensi",
    "for_international_wires_heading": "Per fili internazionali",
    "identifier": "Identificatore: nome completo dello studente e nome e indirizzo del mittente",
    "monthly_option": "Opzione mensile",
    "annual_option": "Opzione 2 pagamenti",
    "upfront_option": "Opzione 1 pagamento",
    "review_and_register": "Revisione e registrazione",
    "contact_us_with_preferred_start_date": "Dopo esserti registrato, contattaci semplicemente con la tua data preferita all'indirizzo {{admissionsEmail}}.",
    "contact_billing_with_questions": "Dopo aver avviato il pagamento, contatta {{billingEmail}} per confermare la tua registrazione.",
    "bank_details_intro": "Fornisci alla tua banca o al servizio di bonifico bancario i seguenti dettagli se scegli l'opzione 1 pagamento o 2 pagamenti. Non accettiamo bonifici per l'opzione di fatturazione mensile.",
    "choose_different_start_date": "Se non sei in grado di unirti alla coorte attuale che inizia il {{startDate}} , puoi preservare la tua ammissione positiva registrandoti ora e scegliendo una delle seguenti date di inizio della coorte:",
    "choose_different_start_date_scholarship": "Se non sei in grado di unirti alla coorte attuale che inizia il {{startDate}} , puoi preservare l'ammissione positiva e il premio della borsa di studio registrandoti ora e scegliendo una delle seguenti date di inizio della coorte:",
    "reapply_at_different_time": "Se non riesci a registrarti, puoi presentare nuovamente domanda entro {{reapplicationMonthYear}}. Non siamo in grado di garantire l'ammissione futura in questo caso.",
    "reapply_at_different_time_scholarship": "Se non riesci a registrarti, puoi presentare nuovamente domanda entro {{reapplicationMonthYear}}. In questo caso non siamo in grado di garantire l'ammissione futura o la stessa borsa di studio.",
    "reapply_at_different_time_no_date": "Se non riesci a registrarti, puoi ripresentare la domanda in un secondo momento. Non siamo in grado di garantire l'ammissione futura in questo caso.",
    "reapply_at_different_time_scholarship_no_date": "Se non riesci a registrarti, puoi ripresentare la domanda in un secondo momento. In questo caso non siamo in grado di garantire l'ammissione futura o la stessa borsa di studio.",
    "for_us_wires_content": "Numero di rotta e transito: {{transitNumber}}",
    "quantic_mailing_address_heading": "{{institutionName}} Indirizzo postale",
    "registered_processing_payment": "Stiamo elaborando il tuo pagamento.",
    "trouble_processing_payment": "Stiamo riscontrando problemi con l'elaborazione del tuo pagamento.",
    "registered_payment_smallprint_once": "Ti verranno addebitati ${{paymentPerInterval}} una volta durante il corso di studio.",
    "plan_description_once": "Include $ {{additionalSavings}} risparmi aggiuntivi.",
    "plan_description_annual": "Include $ {{additionalSavings}} risparmi aggiuntivi.",
    "plan_description_monthly": "Opzione di fatturazione più conveniente.",
    "plan_description_once_partial_scholarship": "Ridotto da $ {{paymentPerIntervalForFullTuition}}. Include $ {{additionalSavings}} risparmi aggiuntivi.",
    "plan_description_annual_partial_scholarship": "Ridotto da $ {{paymentPerIntervalForFullTuition}}. Include $ {{additionalSavings}} risparmi aggiuntivi.",
    "make_single_payment": "Effettua un unico pagamento anticipato e risparmia $ {{additionalSavings}} sul totale delle lezioni.",
    "make_two_payments": "Paga metà ora e metà un anno dopo e risparmia $ {{additionalSavings}} sul totale delle lezioni. I pagamenti in 2 anni solari possono aiutare gli studenti a massimizzare i benefici delle tasse scolastiche dalla loro azienda.",
    "narrative_no_scholarship": "Benvenuto nel programma! Si prega di rivedere le opzioni di pagamento e registrarsi di seguito.",
    "narrative_full_scholarship": "Ti è stata assegnata una borsa di studio completa per il programma. Congratulazioni per questo risultato!",
    "graphic_full_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "registration_deadline_mobile": "Scadenza registrazione: {{registrationDeadline}}.<div style=\"padding-top: 7px;\"> La lezione inizia il {{classStartDate}}.</div>",
    "tuition_details": "Dettagli sulle lezioni",
    "tuition_and_scholarship_details": "Dettagli sulle tasse scolastiche e sulla borsa di studio",
    "graphic_no_scholarship_title": "<b>Congratulazioni</b>",
    "graphic_no_scholarship_subtitle": "Registrati per iscriverti",
    "graphic_partial_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "base_tuition_no_scholarship": "{{programTitle}} Lezioni",
    "base_tuition": "Base {{programTitle}} Lezioni",
    "total_amount": "(per {{numIntervals}} mesi = $ {{totalAmount}} USD)",
    "total_tuition": "Lezioni totali",
    "full_scholarship": "(borsa di studio completa)",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} USD</span>",
    "discount_of_ledger_item": "– $ <span class=\"value\">{{value}} USD</span>",
    "select_payment_method": "Scegli il metodo di pagamento",
    "back_to_plan_selection": "Torna alla selezione del piano",
    "button_view_wire_transfer_instructions": "Visualizza le istruzioni",
    "us_bank_account": "Conto bancario statunitense",
    "card": "Carta",
    "wire_transfer": "Bonifico bancario",
    "transaction_fee": "{{creditCardPaymentFeeRate}}% commissione di transazione",
    "smallprint_transaction_fee": "Commissione non applicabile per carte di debito o carte prepagate. I pagamenti con carte di debito/prepagate avranno il rimborso della commissione immediatamente dopo il checkout.",
    "selected_tuition_plan_once": "Piano di insegnamento selezionato: ${{paymentAmountPerInterval}} USD addebitato una volta",
    "selected_tuition_plan_annual": "Piano di insegnamento selezionato: ${{paymentAmountPerInterval}} USD addebitato due volte",
    "selected_tuition_plan_monthly": "Piano di insegnamento selezionato: ${{paymentAmountPerInterval}} USD al mese",
    "tuition_plan_detail_once": "${{netDiscountAmount}} di risparmio per un singolo pagamento anticipato.<br /> (opzione di insegnamento più bassa)",
    "tuition_plan_detail_monthly": "{{numIntervals}} pagamenti nel corso del programma.<br /> (opzione più conveniente)",
    "single_payment_plan_desc": "${{paymentPerInterval}} USD fatturati una volta",
    "annual_plan_desc": "${{paymentPerInterval}} USD fatturati due volte",
    "monthly_plan_desc": "${{paymentPerInterval}} USD al mese",
    "currently_enrolled_in_x_billed_at_x_once": "",
    "paid_x_of_x": "Hai pagato $ {{netAmountPaid}} su $ {{netRequiredPayment}} USD.",
    "narrative_partial_scholarship": "Ti sono state assegnate {num, plural, =1{ la seguente borsa } other{ le seguenti borse }} di studio , riducendo la tua retta totale. Congratulazioni!",
    "graphic_partial_scholarship_subtitle": "{num, plural, =1{ Borsa di Studio Totale Assegnata } other{ Borse di Studio Totali Assegnate }}",
    "continue_to_register": "Continua con la registrazione",
    "graphic_full_scholarship_subtitle": "Borsa di studio totale assegnata",
    "registered_payment_smallprint_with_fee_once": "Ti verranno addebitati $ {{paymentPerInterval}} una volta durante il corso di studio. Potrebbero essere applicati costi aggiuntivi per le transazioni con carta.",
    "prefer_later_start_date": "Registrati e contatta <a href=\"mailto:admissions@quantic.edu\">ammissions@quantic.edu</a> se preferisci la data di inizio {{upcomingClassStartDate}}.",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;\">Registrati e inviaci un'e-mail se preferisci il<br /> {{upcomingClassStartDate}} data di inizio.</div>",
    "base_tuition_no_program_title": "Lezioni di base",
    "back": "Indietro",
    "bank_details_intro_1_pay_only": "Fornisci alla tua banca o al servizio di bonifico bancario i seguenti dettagli:",
    "buy_now_pay_later": "Paga dopo",
    "upfront_and_save": "Paga in anticipo e <span class=\"green-text\">risparmia $ {{netDiscountAmount}} aggiuntivi<span/>",
    "twice_and_save": "Paga due volte e <span class=\"green-text\">risparmia $ {{netDiscountAmount}} aggiuntivi<span/>",
    "pay_monthly": "Paga mensilmente per la tua comodità",
    "tuition_plan_detail_b_once": "Riduce il totale delle lezioni da $ {{baseTuition}} a $ {{paymentPerInterval}} USD<br /> comprese borse di studio e sconti sul piano",
    "tuition_plan_detail_b_annual": "$ {{paymentPerInterval}} USD fatturati due volte, a distanza di un anno",
    "tuition_plan_detail_b_monthly": "$ {{paymentPerInterval}} USD al mese per {{numIntervals}} mesi",
    "student_preferred_plan": "Piano preferito dagli studenti",
    "registered_payment_smallprint_recurring": "Il tuo prossimo pagamento di <strong>$ {{paymentPerInterval}}</strong> è previsto per <strong>{{nextPaymentDate}}</strong>.",
    "registered_payment_smallprint_with_fee_recurring": "Il tuo prossimo pagamento di <strong>$ {{paymentPerInterval}}</strong> è previsto per <strong>{{nextPaymentDate}}</strong>. Potrebbero essere applicati costi aggiuntivi alle transazioni con carta.",
    "notify_nominator": "Fai conoscere a {{nominatorName}} la buona notizia!",
    "smallprint": "Comprendiamo che in rare circostanze gli studenti non possono partecipare. In questo caso puoi conservare la tua ammissione iscrivendoti adesso e <a ng-click=\"openDeferralModal()\">rinviando ad una lezione futura</a> .",
    "smallprint_no_reapplication_date": "Comprendiamo che in rare circostanze gli studenti non possono partecipare. In questo caso puoi conservare la tua ammissione iscrivendoti adesso e <a ng-click=\"openDeferralModal()\">rinviando ad una lezione futura</a> .",
    "smallprint_scholarship": "Comprendiamo che in rare circostanze gli studenti non possono partecipare. In questo caso puoi conservare l'ammissione e la borsa di studio iscrivendoti ora e <a ng-click=\"openDeferralModal()\">rinviando a un corso futuro</a> .",
    "smallprint_scholarship_no_reapplication_date": "Comprendiamo che in rare circostanze gli studenti non possono partecipare. In questo caso puoi conservare l'ammissione e la borsa di studio iscrivendoti ora e <a ng-click=\"openDeferralModal()\">rinviando a un corso futuro</a> .",
    "tuition_plan_detail_annual": "Risparmio di $ {{netDiscountAmount}} per 2 pagamenti, a un anno di distanza.<br /> (opzione più flessibile)",
    "make_single_payment_partial_scholarship": "Effettua un unico pagamento anticipato e risparmia $ {{additionalSavings}} sul totale delle lezioni.",
    "make_two_payments_partial_scholarship": "Paga metà adesso e metà un anno dopo e risparmia $ {{additionalSavings}} sul totale delle tasse scolastiche. I pagamenti in 2 anni solari possono aiutare gli studenti a massimizzare i benefici didattici offerti dalla loro azienda.",
    "registration_deadline_no_start_date": "La scadenza per registrarsi è {{registrationDeadline}} .",
    "registration_deadline_mobile_no_start_date": "Scadenza per la registrazione: {{registrationDeadline}} .",
    "choose_start_date": "Scegli la data di inizio del corso:",
    "choose_either_cohort": "Come vantaggio dell'ammissione anticipata,<br /> puoi scegliere una delle due coorti.",
    "funding_options": "Contatta <a href=\"mailto:{{fundingEmail}}\" class=\"text-primary\">{{fundingEmail} }</a> per ulteriori opzioni di finanziamento."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.settings = window.locales.zh.settings || {};window.locales.zh.settings.tuition_and_registration = window.locales.zh.settings.tuition_and_registration || {};window.locales.zh.settings.tuition_and_registration = {...window.locales.zh.settings.tuition_and_registration, ...{
    "allow_classmates_to_see": "允许同学查看我的个人资料",           
    "header_choose_your_tuition_plan": "选择你的学费计划",           
    "narrative_all_paid": "你已经全额支付了学费。谢谢你！",           
    "new_browser_register": "请使用系统自带的web浏览器进行注册。",           
    "new_browser_payment_details": "请使用系统自带的web浏览器来修改支付方式。",           
    "confirm_message": "请于 <strong>{{registrationDeadline}}</strong>前进行注册，以确保自己成为班级的一员。",           
    "confirm_message_past_deadline": "为确保自己成功加入该班级，请立即注册。",           
    "register": "注册",           
    "launch_browser": "启动Web浏览器",           
    "stripe_description_monthly_tuition": "月度学费计划",           
    "stripe_description_annual_tuition": "年度学费计划",           
    "stripe_description_once_tuition": "单次支付",           
    "stripe_panel_label_pay_tuition": "支付学费 ",           
    "processing": "处理中...",           
    "scholarship_award": "奖学金",           
    "please_re_enter_payment_details": "请重新输入付款信息以解决问题。",           
    "currently_enrolled_in_x_billed_at_x_monthly_during": "你目前注册了月度学费计划，<b>每月支付 $ {{paymentPerInterval}} USD</b>。",           
    "currently_enrolled_in_x_billed_at_x_monthly": "你目前注册了月度学费计划，<b>每月支付 $ {{paymentPerInterval}} USD</b>。",           
    "currently_enrolled_in_x_billed_at_x_annual_during": "你目前注册了年度学费计划，在学习期间支付<b>两次费用 $ {{paymentPerInterval}} USD</b>。",           
    "currently_enrolled_in_x_billed_at_x_annual": "你目前注册了年度学费计划，共需支付<b>两次费用 $ {{paymentPerInterval}} USD</b>。",           
    "confirm_message_before_deadline": "注册将于<br class=\"visible-xs\" /> <strong>{{registrationOpen}}</strong>中开启。",           
    "choose_your_plan": "选择你的学费计划：",           
    "explain_the_options": "阐明选项内容",           
    "choose_tuition": "选择适合你的学费选项",           
    "option_one": "选项1",           
    "option_two": "选项2",           
    "option_three": "选项3",           
    "header_register": "注册{{programTitle}}",           
    "stripe_name": "{{brandName}} {{fullTitle}}",           
    "scholarship_award_with_label": "{{scholarshipLabel}}奖学金",           
    "registered_contact_support": "请发送邮件至<a href=\"mailto:{{billingEmail}}\">{{billingEmail}}</a>以查询账户信息和付款收据。",           
    "registered_manage_billing": "计费管理",           
    "registered_manage_billing_payment_trouble": "解决付款信息",           
    "registered_manage_billing_view_payment_history": "查看支付记录",           
    "contact_bursar": "联系财务部门",           
    "email_our_bursar": "给我们的财务部门发送电子邮件，询问如何通过电汇进行注册和支付。",           
    "available_plans": "可选的学费计划：",           
    "which_section_is_right": "你适合哪一组？",           
    "two_different_cohort_sections": "你所在的地区提供两种不同的同期组。",           
    "global_section_explanation": "我们的<b>全球组</b>拥有来自30多个国家的学员，在小组项目和练习中可发挥更多的国际视角。班级中一半以上的学员生活在北美和西欧。",           
    "both_sections_same_explanation": "两组均使用相同的课程材料、项目和案例研究，同时获得相同的Quantic学位。学员都有机会选择参加我们的现场活动，并加入完整的全球校友网络。",           
    "choose_your_section": "选择你的同期组来审查奖学金和学费选项。",           
    "whats_the_difference": "有何区别？",           
    "global_section": "全球组",           
    "regional_section": "南亚/东南亚组",           
    "global_section_subtext": "与来自30多个国家的学员一起学习和合作。",           
    "regional_section_subtext": "只能和同一地区的同学一起学习和合作。更多的奖学金可以减少学费。",           
    "regional_section_subtext_full_scholarship": "只能和同一地区的同学一起学习和合作。",           
    "regional_section_explanation": "我们的<b>{{regionalSectionName}}</b>只有来自该地区的学员，通过企业赞助和增加的奖学金奖励，在实现更广泛的本地网络的同时降低学费。",
    "registration_deadline": "注册截止日期为<br class=\"visible-xs\" />{{registrationDeadline}}。<br class=\"visible-xs\" /><div class=\"visible-xs\" style=\"padding-top: 10px;\"></div>课程将于{{classStartDate}}开始。",           
    "action_required": "需要采取的行动",
    "confirm_payment": "我们已向您发送了一封电子邮件，以确认您最近的 $ {{paymentPerInterval}} USD 付款。",
    "additional_steps_required": "为保护您免遭欺诈，您的银行需要额外的步骤来确保在线交易获得授权。请确认您向 Quantic 付款以完成您的注册。",
    "plan_description_monthly_partial_scholarship": "从每月 $ {{paymentPerIntervalForFullTuition}} USD 减少。",
    "payment_options": "付款方式",
    "make_monthly_payments": "在计划期间进行{{numIntervals}}方便的每月付款。",
    "refund_policy": "我们慷慨的<a ng-click=\"loadFaqUrl('/help/tuition-refund-policy', '_blank')\">退款政策</a>包括在开课前全额退款。",
    "deferring_admission": "推迟入学",
    "wiring_funds": "接线资金",
    "our_bank_heading": "我们的银行",
    "our_account_name_heading": "我们的账户名称",
    "our_account_number_heading": "我们的帐号",
    "for_us_wires_heading": "美国电线",
    "for_international_wires_heading": "国际电汇",
    "identifier": "标识符：学生的全名和寄件人的姓名和地址",
    "monthly_option": "每月选项",
    "annual_option": "2-支付选项",
    "upfront_option": "1-支付选项",
    "review_and_register": "查看和注册",
    "contact_us_with_preferred_start_date": "注册后，只需通过{{admissionsEmail}}与我们联系并告知您的首选日期。",
    "contact_billing_with_questions": "开始付款后，联系{{billingEmail}}以确认您的注册。",
    "bank_details_intro": "如果选择 1-pay 或 2-pay 选项，请向您的银行或电汇服务提供以下详细信息。对于按月计费的选项，我们不接受电汇。",
    "choose_different_start_date": "如果您无法加入从{{startDate}}开始的当前队列，您可以通过立即注册并选择以下队列开始日期之一来保持您的积极录取：",
    "choose_different_start_date_scholarship": "如果您无法加入从{{startDate}}开始的当前队列，您可以通过立即注册并选择以下队列开始日期之一来保留您的积极录取和奖学金：",
    "reapply_at_different_time": "如果您无法注册，您可以在{{reapplicationMonthYear}} 。在这种情况下，我们无法保证将来会被录取。",
    "reapply_at_different_time_scholarship": "如果您无法注册，您可以在{{reapplicationMonthYear}} 。在这种情况下，我们无法保证未来的录取或相同的奖学金。",
    "reapply_at_different_time_no_date": "如果您无法注册，您可以稍后重新申请。在这种情况下，我们无法保证将来会被录取。",
    "reapply_at_different_time_scholarship_no_date": "如果您无法注册，您可以稍后重新申请。在这种情况下，我们无法保证未来的录取或相同的奖学金。",
    "for_us_wires_content": "路线和中转号码： {{transitNumber}}",
    "quantic_mailing_address_heading": "{{institutionName}}邮寄地址",
    "registered_processing_payment": "我们正在处理您的付款。",
    "trouble_processing_payment": "我们在处理您的付款时遇到问题。",
    "registered_payment_smallprint_once": "在学习期间，您将被收取 $ {{paymentPerInterval}} USD 一次。",
    "plan_description_once": "包括 $ {{additionalSavings}} USD 的额外节省。",
    "plan_description_annual": "包括 $ {{additionalSavings}} USD 的额外节省。",
    "plan_description_monthly": "最方便的计费选项。",
    "plan_description_once_partial_scholarship": "从 $ {{paymentPerIntervalForFullTuition}} USD 减少。包括 $ {{additionalSavings}} USD 的额外节省。",
    "plan_description_annual_partial_scholarship": "从 $ {{paymentPerIntervalForFullTuition}} USD 减少。包括 $ {{additionalSavings}} USD 的额外节省。",
    "make_single_payment": "支付一笔预付款，即可从总学费中节省 $ {{additionalSavings}} USD。",
    "make_two_payments": "现在支付一半，一年后支付一半，即可节省 $ {{additionalSavings}} USD 的总学费。在 2 个日历年内付款可以帮助学生最大限度地从公司获得学费优惠。",
    "narrative_no_scholarship": "欢迎收看节目！请查看付款选项并在下方注册。",
    "narrative_full_scholarship": "您已获得该计划的全额奖学金。祝贺您取得这一成就！",
    "graphic_full_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "registration_deadline_mobile": "报名截止日期： {{registrationDeadline}} 。<div style=\"padding-top: 7px;\">课程于{{classStartDate}}开始。</div>",
    "tuition_details": "学费详情",
    "tuition_and_scholarship_details": "学费和奖学金详情",
    "graphic_no_scholarship_title": "<b>恭喜</b>",
    "graphic_no_scholarship_subtitle": "报名参加",
    "graphic_partial_scholarship_title": "<strong>$ {{totalScholarship}}</strong> USD",
    "base_tuition_no_scholarship": "{{programTitle}}学费",
    "base_tuition": "基础{{programTitle}}学费",
    "total_amount": "（ {{numIntervals}}个月 = $ {{totalAmount}} USD）",
    "total_tuition": "总学费",
    "full_scholarship": "(全额奖学金)",
    "cost_of_ledger_item": "$ <span class=\"value\">{{value}} USD</span>",
    "discount_of_ledger_item": "– $ <span class=\"value\">{{value}} USD</span>",
    "select_payment_method": "选择付款方式",
    "back_to_plan_selection": "返回计划选择",
    "button_view_wire_transfer_instructions": "查看说明",
    "us_bank_account": "美国银行账户",
    "card": "卡片",
    "wire_transfer": "电汇",
    "transaction_fee": "{{creditCardPaymentFeeRate}}% 交易费",
    "smallprint_transaction_fee": "费用不适用于借记卡或预付卡。使用借记卡/预付卡付款将在结帐后立即退还费用。",
    "selected_tuition_plan_once": "选定的学费计划：$ {{paymentAmountPerInterval}} USD 收取一次",
    "selected_tuition_plan_annual": "选定的学费计划：收取两次 $ {{paymentAmountPerInterval}} USD",
    "selected_tuition_plan_monthly": "选定的学费计划：每月 $ {{paymentAmountPerInterval}} USD",
    "tuition_plan_detail_once": "单笔预付款可节省 $ {{netDiscountAmount}} USD。<br /> （最低学费选项）",
    "tuition_plan_detail_monthly": "{{numIntervals}}在计划过程中付款。<br /> （最方便的选择）",
    "single_payment_plan_desc": "$ {{paymentPerInterval}} USD 计费一次",
    "annual_plan_desc": "$ {{paymentPerInterval}} USD 计费两次",
    "monthly_plan_desc": "每月 $ {{paymentPerInterval}} USD",
    "currently_enrolled_in_x_billed_at_x_once": "",
    "paid_x_of_x": "您已经从 $ {{netAmountPaid}} USD 中支付了 $ {{netRequiredPayment}} USD。",
    "narrative_partial_scholarship": "您已获得以下{num, plural, =1{ 奖学金 } other{ 奖学金 }} ，减少了您的总学费。恭喜！",
    "graphic_partial_scholarship_subtitle": "{num, plural, =1{ 奖学金 } other{ 奖学金 }}总额",
    "continue_to_register": "继续注册",
    "graphic_full_scholarship_subtitle": "奖学金总额",
    "registered_payment_smallprint_with_fee_once": "在学习期间，您将被收取 $ {{paymentPerInterval}} USD 一次。信用卡交易可能会收取额外费用。",
    "prefer_later_start_date": "如果您希望{{upcomingClassStartDate}}开始日期，请注册并联系<a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> 。",
    "prefer_later_start_date_mobile": "<div style=\"padding-top: 2px;\">如果您愿意，请注册并发送电子邮件给我们<br />{{upcomingClassStartDate}}开始日期。</div>",
    "base_tuition_no_program_title": "基础学费",
    "back": "后退",
    "bank_details_intro_1_pay_only": "向您的银行或电汇服务提供以下详细信息：",
    "buy_now_pay_later": "以后支付",
    "upfront_and_save": "预先付款并<span class=\"green-text\">节省额外的 $ {{netDiscountAmount}} USD<span/>",
    "twice_and_save": "支付两次并<span class=\"green-text\">节省额外 $ {{netDiscountAmount}} USD<span/>",
    "pay_monthly": "按月付费，方便您",
    "tuition_plan_detail_b_once": "将总学费从 $ {{baseTuition}} USD 减少到 $ {{paymentPerInterval}} USD<br />包括奖学金和计划折扣",
    "tuition_plan_detail_b_annual": "$ {{paymentPerInterval}} USD 两次计费，间隔一年",
    "tuition_plan_detail_b_monthly": "每月 $ {{paymentPerInterval}} USD，持续{{numIntervals}}个月",
    "student_preferred_plan": "学生首选计划",
    "registered_payment_smallprint_recurring": "您的下一次付款金额为<strong>$ {{paymentPerInterval}}</strong>预计于<strong>{{nextPaymentDate}}</strong>进行。",
    "registered_payment_smallprint_with_fee_recurring": "您的下一次付款金额为<strong>$ {{paymentPerInterval}}</strong>预计于<strong>{{nextPaymentDate}}</strong>进行。信用卡交易可能收取额外费用。",
    "notify_nominator": "让{{nominatorName}}知道这个好消息！",
    "smallprint": "我们理解，在极少数情况下，学生无法上课。在这种情况下，您可以通过立即注册并<a ng-click=\"openDeferralModal()\">推迟到以后的课程来</a>保留您的入学资格。",
    "smallprint_no_reapplication_date": "我们理解，在极少数情况下，学生无法上课。在这种情况下，您可以通过立即注册并<a ng-click=\"openDeferralModal()\">推迟到以后的课程来</a>保留您的入学资格。",
    "smallprint_scholarship": "我们理解，在极少数情况下，学生无法参加。在这种情况下，您可以通过立即注册并<a ng-click=\"openDeferralModal()\">推迟到以后的课程来</a>保留您的入学资格和奖学金。",
    "smallprint_scholarship_no_reapplication_date": "我们理解，在极少数情况下，学生无法参加。在这种情况下，您可以通过立即注册并<a ng-click=\"openDeferralModal()\">推迟到以后的课程来</a>保留您的入学资格和奖学金。",
    "tuition_plan_detail_annual": "间隔一年的两次付款可节省 $ {{netDiscountAmount}}美元。<br /> （更灵活的选择）",
    "make_single_payment_partial_scholarship": "只需预付一次即可节省总学费 $ {{additionalSavings}} 。",
    "make_two_payments_partial_scholarship": "现在支付一半，一年后支付一半，可节省总学费 $ {{additionalSavings}} 。两年内付款可帮助学生最大限度地享受公司提供的学费优惠。",
    "registration_deadline_no_start_date": "报名截止日期为{{registrationDeadline}} 。",
    "registration_deadline_mobile_no_start_date": "报名截止日期： {{registrationDeadline}} 。",
    "choose_start_date": "选择你的课程开始日期：",
    "choose_either_cohort": "作为提前录取的福利，<br />你可以选择任意一个群体。",
    "funding_options": "联系<a href=\"mailto:{{fundingEmail}}\" class=\"text-primary\">{{fundingEmail} }</a>获取更多融资选择。"
}
};